import React, { useState, useEffect } from 'react'
import { BrowserRouter, Switch, Route, Link, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Navbar, Nav, NavDropdown, Badge, ListGroup } from 'react-bootstrap'
import axios from 'axios';
import logo from '../img/online-shopping.png';

function Sidebar(){
    const history = useHistory()
// ตรวจสอบ function ที่กำหนดแก่สมาชิกทั้งหมด
  const [open_cash, setOpen_cash] = useState(0)
  const [add_stock, setAdd_stock] = useState(0)
  const [create_stock, setCreate_stock] = useState(0)
  const [change_stock, setChange_stock] = useState(0)
  const [tranfer, setTranfer] = useState(0)
  const [report_today, setReport_today] = useState(0)
  const [report_fit, setReport_fit] = useState(0)
  const [report_cost, setReport_cost] = useState(0)
  const [report_end_point, setReport_end_point] = useState(0)
  const [detail_store, setDetail_store] = useState(0)
  const [account, setAccount] = useState(0)
  const [security, setSecurity] = useState(0)
  const [store_private, setStore_private] = useState(0)
  const [status_funtion , setStatus_funtion] = useState(false)
  function logout() {
    const lo = window.confirm('ยืนยันการออกจากระบบ')
    if (lo === true) {
      localStorage.clear();
      history.push('/')
    }
  }

  const get_function = () => {
    axios.post('http://apichatapi.ddns.net/api/pos/get_account_feature.php', { store_id: localStorage.getItem('store_id'), emp_user: localStorage.getItem('emp_user') })
      .then(res => {
        if (res.data.length > 0) {
          res.data.map((data) => {
            setOpen_cash(data.open_cash)
            setAdd_stock(data.add_to_stock)
            setCreate_stock(data.create_list_item)
            setChange_stock(data.chang_list_product)
            setTranfer(data.tranfer_product)
            setReport_today(data.report_today)
            setReport_fit(data.report_fit)
            setReport_end_point(data.report_end_point)
            setReport_cost(data.report_cost)
            setDetail_store(data.data_store)
            setStore_private(data.private)
            setSecurity(data.security)
            setAccount(data.account)
            setStatus_funtion(true)
          })
        }
      })
  }

  useEffect(() => {
    get_function()
  })

  const Header = () => {
    return (
      <>
        {/* <Navbar collapseOnSelect className='pt-1 pb-1 shadow bg-white' fixed='' variant='light' style={{background:'#4caf50'}}> */}
        {/* <div className="container"> */}
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
        {/* <Nav className="me-auto" > */}
        {/* <Link to='/main' className='nav-link'>หน้าหลัก</Link> */}
        {/* <Link to='/sell' className='nav-link'>ระบบขายสินค้า</Link> */}
        {/* <NavDropdown title="ระบบคลังสินค้า" id="basic-nav-dropdown" style={{color:'white'}}> */}
        {/* <Link to='/stock/addStock' className='dropdown-item'>รับสินค้าเข้าสต๊อก</Link> */}
        {/* <Link to='/stock/newProduct' className='dropdown-item'>เพิ่มสินค้าใหม่เข้าสต๊อก</Link> */}
        {/* <Link to='/stock/changeProduct' className='dropdown-item'>ปรับยอดสต๊อก</Link> */}
        {/* <NavDropdown.Divider /> */}
        {/* <Link to='/stock/changeProduct' className='dropdown-item'>เบิกสินค้าออก</Link> */}
        {/* <Link to='/stock/changeProduct' className='dropdown-item'>รับคืนสินค้า</Link> */}
        {/* </NavDropdown> */}

        {/* <NavDropdown title="ระบบรายงาน" id="basic-nav-dropdown"  className='text-white'> */}
        {/* <Link to='/report/toDay' className='dropdown-item'>ยอดขายรายวัน</Link> */}
        {/* <Link to='/report/toDay' className='dropdown-item'>ยอดขายรายปี</Link> */}
        {/* <Link to='/report/dayCustome' className='dropdown-item'>ยอดขายตามกำหนด</Link> */}
        {/* <Link to='/report/toDay' className='dropdown-item'>ยอดขายตามประเภทสินค้า</Link> */}
        {/* <NavDropdown.Divider /> */}
        {/* <Link to='/report/CostAllCheck' className='dropdown-item'>ต้นทุนสินค้าทั้งหมด</Link> */}
        {/* <Link to='/report/productEndPoint' className='dropdown-item'>สินค้าถึงจุดสั่งซื้อ</Link> */}
        {/* </NavDropdown> */}

        {/* <NavDropdown title="ตั้งค่าบัญชี" id="basic-nav-dropdown"  className='text-white'> */}
        {/* <Link to='/account/store' className='dropdown-item'>ข้อมูลร้านค้า</Link> */}
        {/* <Link to='/account/account' className='dropdown-item'>ข้อมูลพนักงาน</Link> */}
        {/* <Link to='/account/security' className='dropdown-item'>ความปลอดภัย</Link> */}
        {/* <Link to='/account/private' className='dropdown-item'>กำหนดสิทธิ์การเข้าถึง</Link> */}
        {/* </NavDropdown> */}
        {/* </Nav> */}

        {/* <Nav> */}
        {/* <Link to={'/'} className='nav-link' eventKey={2}> */}
        {/* ออกจากระบบ */}
        {/* </Link> */}
        {/* </Nav> */}
        {/* </Navbar.Collapse> */}
        {/* </div> */}
        {/* </Navbar> */}
        <ListGroup>
          <>
            <div className=' mb-1' style={{ borderBottom: '1px solid silver' }}>
              <div className="d-flex">
                <div className='col-4 text-right'>
                <img src={logo} alt="" style={{ marginTop:'5px' , boxShadow:'0px 1px 2px silver' , marginBottom:'5px' , marginLeft:'14px' , width:'50px' , height:'50px', objectFit:'cover' , borderRadius:'10px'}} />
                </div>
                <div className="col-8" style={{color:'#1976d2'}}>
                  <div style={{fontSize:'22px'}}><b>CHECK POS</b></div>
                  <small>ระบบขายสินค้า</small>
                </div>
              </div>

            </div>

            <Link to='/main' className='link-group'><li className='li-link-title' style={{ borderLeft: '#02aee2 solid 5px' }}><i class="bi bi-app"></i> &nbsp;หน้าหลัก</li></Link>
            <Link to='/sell' className='link-group'><li className='li-link-title' style={{ borderLeft: '#4caf50 solid 5px' }}><i class="bi bi-basket"></i> &nbsp;ระบบขายสินค้า</li></Link>

            <li className='li-title' style={{ borderLeft: '#8bc34a solid 5px' }}> <i class="bi bi-table"></i>&nbsp; คลังสินค้า</li>
            {
              add_stock == 1
                ? <Link to='/stock/addStock' className='link-group'><li className='li-group'> <span className='bi bi-cloud-download' /> &nbsp;&nbsp;&nbsp;รับสินค้าเข้าคลัง</li></Link>
                : <></>
            }
            {
              create_stock == 1
                ? <Link to='/stock/newProduct' className='link-group'><li className='li-group'> <span className='bi bi-plus-circle' /> &nbsp;&nbsp;&nbsp;สร้างรายการสินค้าใหม่</li></Link>
                : <></>
            }
            {
              change_stock == 1
                ? <Link to='/stock/changeProduct' className='link-group'><li className='li-group'> <span className='bi bi-view-list' /> &nbsp;&nbsp;&nbsp;เปลี่ยนแปลงข้อมูลสินค้า</li></Link>
                : <></>
            }
            {
              // tranfer == 1
              //   ? <Link to='/stock/tranferProduct' className='link-group'><li className='li-group'> <span className='bi bi-arrow-left-right' /> &nbsp;&nbsp;&nbsp;โอนสินค้าระหว่างสาขา</li></Link>
              //   : <></>
            }

            <li className='li-title' style={{ borderLeft: '#cddc39 solid 5px' }}><i class="bi bi-flag"></i>&nbsp; รายงาน</li>
            {
              report_today == 1
                ? <Link to='/report/toDay' className='link-group'><li className='li-group'> <span className='bi bi-bar-chart-line' />&nbsp;&nbsp;&nbsp; การขายประจำวัน</li></Link>
                : <></>
            }
            {
              report_fit == 1
                ? <Link to='/report/dayCustome' className='link-group'><li className='li-group'> <span className='bi bi-calendar-day' />&nbsp;&nbsp;&nbsp; การขายตามวันที่กำหนด</li></Link>
                : <></>
            }
            {
              report_end_point == 1
                ? <Link to='/report/productEndPoint' className='link-group'><li className='li-group'> <span className='bi bi-box-seam' />&nbsp;&nbsp;&nbsp; สินค้าถึงจุดสั่งซื้อ</li></Link>
                : <></>
            }
            {
              report_cost == 1
                ? <Link to='/report/CostAllCheck' className='link-group'><li className='li-group'> <span className='bi bi-archive' />&nbsp;&nbsp;&nbsp; ต้นทุนสินค้า</li></Link>
                : <></>
            }

            <li className='li-title' style={{ borderLeft: '#ffc107 solid 5px' }}><i class="bi bi-person-bounding-box"></i>&nbsp; บัญชีผู้ใช้</li>
            {
              detail_store == 1
                ? <Link to='/account/store' className='link-group'><li className='li-group'> <span className='bi bi-shop' /> &nbsp;&nbsp;&nbsp;ข้อมูลร้านค้า</li></Link>
                : <></>
            }
            {
              account == 1
                ? <Link to='/account/account' className='link-group'><li className='li-group'> <span className='bi bi-book' /> &nbsp;&nbsp;&nbsp;ข้อมูลพนักงาน</li></Link>
                : <></>
            }
            {
              security == 1
                ? <Link to='/account/security' className='link-group'><li className='li-group'> <span className='bi bi-shield-check' /> &nbsp;&nbsp;&nbsp;ความปลอดภัย</li></Link>
                : <></>
            }
            {
              store_private == 1
                ? <Link to='/account/private' className='link-group'><li className='li-group'> <span className='bi bi-key' /> &nbsp;&nbsp;&nbsp;กำหนดสิทธิการเข้าถึง</li></Link>
                : <></>
            }

            <div className='link-group' style={{ cursor: 'pointer', borderLeft: '#ff9800 solid 5px' }} onClick={logout}><li className='li-link-title'><i class="bi bi-box-arrow-right"></i>&nbsp; ออกจากระบบ</li></div>
          </>
        </ListGroup>
      </>
    )
  }

  return <Header/>
}

export default Sidebar