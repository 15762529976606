import React, { useState, useEffect } from 'react'
import render from 'react-dom'
import { Container, Row, Col, Card, Table, Modal, Button, Form } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'
import '../../App.css'
import Select from 'react-select'

const Create = () => {
    const [barcode , setBarcode] = useState('')
    const [pro_name , setPro_name] = useState('')
    const [type , setType] = useState('')
    const [cost , setCost] = useState('')
    const [price , setPrice] = useState('')
    const [count , setCount] = useState('')
    const [end_point , setEnd_point] = useState('')
    const [cate , setCate] = useState([])
    const add_product = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/create.php',
        {
            barcode : barcode,
            pro_name: pro_name,
            type : type,
            cost: cost,
            price: price,
            count: count,
            end_point: end_point,
            database : localStorage.getItem('database')
        }).then(res=>{
            if(res.data.status === 200) {
                alert('สร้างรายการสินค้าสำเร็จ')
                setBarcode('')
                setPro_name('')
                setType('')
                setCost('')
                setPrice('')
                setCount('')
                setEnd_point('')
            }
        }).catch(err => {
            alert(err)
        })
    }
    const cate_get = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/cate.php',{database : localStorage.getItem('database')}).then(res => {
            setCate(res.data)
        })
    }
    useEffect(()=>{
        cate_get()
    },[])

    return (
        <>
            <div className='d-flex justify-content-center mt-3 container' style={{ backgroundColor: '#f5f5f5' , boxShadow:'0px 1px 2px silver' , borderRadius:'10px' }}>
                <div className='col-12 col-lg-6 col-xl-5 mt-4 m-1 p-3'>
                    <div className='mt-2 mb-2 h5 text-center color-primary'>สร้างรายการสินค้า</div>
                    <hr className='mt-1 mb-3' />
                    <Table borderless size='sm'>
                        <tbody style={{ verticalAlign: 'middle' }}>
                            <tr>
                                <td>รหัสสินค้า</td>
                                <td><input type="text" className='col-12 form-control input_box' value={barcode} onChange={(e) => setBarcode(e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td>ชื่อสินค้า</td>
                                <td><input type="text" className='col-12 form-control input_box' value={pro_name} onChange={(e) => setPro_name(e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td>ประเภท</td>
                                <td>
                                    <input type="text" list='cate' className='col-8 form-control input_box' value={type} onChange={(e) => setType(e.target.value)}/>
                                    <datalist id='cate'>
                                        {
                                            cate.map(cate=>{
                                                return(
                                                    <option value={cate.cate_name}>{cate.cate_name}</option>
                                                )
                                            })
                                        }
                                    </datalist>
                                </td>
                            </tr>
                            <tr>
                                <td>ต้นทุนสินค้า</td>
                                <td><input type="text" className='col-7 form-control input_box' value={cost} onChange={(e) => setCost(e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td>ราคาสินค้า</td>
                                <td><input type="text" className='col-7 form-control input_box' value={price} onChange={(e) => setPrice(e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td>จำนวนคงเหลือ</td>
                                <td><input type="text" className='col-7 form-control input_box' value={count} onChange={(e) => setCount(e.target.value)}/></td>
                            </tr>
                            <tr>
                                <td>จุดสั่งซื้อ</td>
                                <td><input type="text" className='col-7 form-control input_box' value={end_point} onChange={(e) => setEnd_point(e.target.value)}/></td>
                            </tr>
                            {/* <tr>
                                <td>หน่วยสินค้า</td>
                                <td><input type="text" className='form-control col-7 input_box' /></td>
                            </tr> */}
                        </tbody>
                    </Table>
                    <center>
                        <Button className='col-12 col-xl-6 border-0' style={{backgroundColor:'#2196f3',boxShadow:'0px 1px 2px silver'}} onClick={() => add_product()}>สร้างรายการสินค้า</Button>
                    </center>
                    <p></p>
                </div>
            </div>
        </>
    )
}

export default Create