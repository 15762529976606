import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Table, Modal, Button, Alert, Tab } from 'react-bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import date from 'date-and-time';
import Clock from 'react-live-clock';
import { BrowserRouter, Link, Switch, Route, useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { Line } from 'react-chartjs-2';
import axios from 'axios';

const Report_response = () => {
    const styles = {
        flex_advance: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            // backgroundColor:'white',
            // margin:'20px 100px 0px 100px',
            // borderRadius:'10px',
            // boxShadow:'0px 1px 2px silver'
        }
    }
    const { start, end } = useParams(); //รับวันที่
    const [data, setData] = useState([])
    const months_eng = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const months_thai = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤษจิกายน', 'ธันวาคม']
    var discount = 0
    var total_price = 0
    var net_price = 0
    var earn = 0

    const [date_today, setDate_today] = useState('')
    const now = new Date();
    const fetch = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/report_today.php', { database: localStorage.getItem('database'), start: date.format(new Date(start) , 'YYYY-MM-DD 00:00:00'), end: date.format(new Date(end), 'YYYY-MM-DD 23:59:59') }).then(res => {
            if (res.data.length > 0) {
                setData(res.data)
            }
        })
    }

    function print_slip(bill_id) {
        window.open("http://apichatapi.ddns.net/api/pos/print_slip.php?bill_id=" + bill_id + "&store_id="+localStorage.getItem("store_id") + "&database="+ localStorage.getItem('database'), "_blank", "width=300,height=1000")
    }

    function print_a4(bill_id, name, address) {
        window.open("http://apichatapi.ddns.net/api/pos/print_a4.php?bill_id=" + bill_id + "&store_id="+localStorage.getItem("store_id") + "&database="+ localStorage.getItem('database'), "_blank", "width=1024,height=768")
    }


    function response_date() {
        const day = date.format(new Date(start), 'DD');
        const M = date.format(new Date(start), 'MMMM');
        const year = +date.format(new Date(start), 'YYYY') + 543;

        const day_1 = date.format(new Date(end), 'DD');
        const M_1 = date.format(new Date(end), 'MMMM');
        const year_1 = +date.format(new Date(end), 'YYYY') + 543;

        let date_set_res = ''
        let date_set_res_1 = ''
        for (let i = 0; i < months_eng.length; i++) {
            if (M === months_eng[i]) {
                date_set_res = months_thai[i]
            }
        }

        for (let i = 0; i < months_eng.length; i++) {
            if (M_1 === months_eng[i]) {
                date_set_res_1 = months_thai[i]
            }
        }
        setDate_today(day + ' ' + date_set_res + ' ' + year + ' - ' + day_1 + ' ' + date_set_res_1 + ' ' + year_1)
    }
    const Earn_fires = () => {
        var earn_fires = 0
        data.reverse().map((item, index) => {
            JSON.parse(item.bill_detail).map(ser => {
                earn_fires += +ser.earn
            })
        })
        return <>{earn_fires.toFixed(2)}</>
    }

    useEffect(() => {
        fetch()
        response_date()
    }, [])
    return (
        <>
        <Link to={`/report/dayCustome`} style={{ textDecoration: 'none', marginLeft: '10px' , color:'white' }}> <span className='bi bi-arrow-left-circle'></span> เลือกวันที่ต้องการเรียกดูประวัติ</Link>
            <div className="" style={styles.flex_advance}>
                <div className='text-center mt-3 text-white' style={{ fontSize: '18px' }}>
                    รายงานการขายประจำวันที่ <br /><small>{date_today}</small>
                </div>
                <div className='text-center mt-3 text-white' style={{ fontSize: '20px'}}>
                    **** กำไรสุทธิ <Earn_fires/>.- ****
                </div>
                <div className='text-center mt-3 col-lg-12 col-xl-11 bg-white'>
                    <Table responsive='lg' style={{ boxShadow: '0px 1px 2px silver' }} borderless hover>
                        <thead className='text-header' style={{boxShadow: '0px 1px 2px silver',borderTop:'5px solid rgb(23, 105, 170)' }}>
                            <tr>
                                <th>เลขที่บิล</th>
                                <th>เวลา</th>
                                <th>ราคาสินค้า</th>
                                <th>ส่วนลด</th>
                                <th>ราคาสุทธิ</th>
                                <th>กำไร</th>
                                <th>รายละเอียดเพิ่มเติม</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.slice().reverse().map((item, index) => {
                                    var total = 0;

                                    discount += +item.discount
                                    total_price += +item.total_price
                                    net_price += +item.net_price
                                    
                                    JSON.parse(item.bill_detail).map(ser => {
                                        total += +ser.earn
                                        earn += +ser.earn
                                    })
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{item.bill_id}</td>
                                                <td>{date.format(new Date(item.date), 'DD/MM/YYYY  H:m:ss')}</td>
                                                <td>{item.total_price}</td>
                                                <td>{item.discount}</td>
                                                <td>{item.net_price}</td>
                                                <td>{total.toFixed(2)}</td>
                                                <td className='col-xl-2'>
                                                    <span onClick={() => print_slip(item.bill_id)} style={{ cursor: 'pointer', color: '#1769aa' }}>Slip <span className='fas fa-chevron-right'></span></span> &nbsp; &nbsp; &nbsp;
                                                    <span onClick={() => print_a4(item.bill_id)} style={{ cursor: 'pointer', color: '#357a38' }}>Receipt <span className='fas fa-chevron-right'></span></span></td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan='2'>สรุปรายงาน</th>
                                <th>{total_price}</th>
                                <th>{discount}</th>
                                <th>{net_price.toFixed(2)}</th>
                                <th>{earn.toFixed(2)}</th>                                
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            </div>
        </>
    )
}

const Report_from = () => {
    const { url } = useRouteMatch()
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')

    const styles = {
        flex_advance: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '80vh'
        }
    }

    const Btn = () => {
        if(start !== '' && end !== '') {
            return (
                <Link to={`${url}/${start}&${end}`} onClick={() => {setStart('');setEnd('')}} className='col-12 col-xl-12 mt-4 border-0 btn text-white' style={{ backgroundColor: '#2196f3', boxShadow: '0px 1px 2px silver' }}>เรียกดู</Link>      
            )
        }else{
            return (
                <div className='col-12 col-xl-12 mt-4 border-0 btn text-secondary bg-light'>เรียกดู</div>      
            )
        }
    }
    return (
        <>
            <BrowserRouter basename='/demo'>
                <Switch>
                    <Route path={`${url}`} exact>
                        <div className="" style={styles.flex_advance}>
                            <Col sm={10} lg={3} xl={3}>
                                <div className="p-3 bg-white" style={{boxShadow:'0px 1px 2px silver',borderRadius:'10px'}}>
                                    <div className='mb-4 h5' style={{color:'#1769aa'}}>เลือกวันที่ต้องการเรียกดูประวัติ</div>
                                    <label htmlFor="">จากวันที่</label>
                                    <input type='date' className="form-control input_box" onChange={(e) => setStart(e.target.value)} />
                                    <label htmlFor="" className='mt-3'>ถึงวันที่</label>
                                    <input type='date' className="form-control input_box" onChange={(e) => setEnd(e.target.value)} />

                                    <center>
                                        {/* <Link to={`${url}/${start}&${end}`} className='col-12 col-xl-12 mt-4 border-0 btn text-white' style={{ backgroundColor: '#2196f3', boxShadow: '0px 1px 2px silver' }}>เรียกดู</Link>        */}
                                        <Btn/>
                                    </center>
                                </div>
                            </Col>
                        </div>
                    </Route>
                    <Route path={`${url}/:start&:end`}>
                        <Report_response />
                    </Route>
                </Switch>
            </BrowserRouter>
        </>
    )
}

export default Report_from