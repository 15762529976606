import React, { useState, useEffect } from 'react'
import { BrowserRouter, Switch, Route, Link, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Navbar, Nav, NavDropdown, Badge, ListGroup } from 'react-bootstrap'
import './App.css';

import Menu_user from './paper/main/menu_user'
import Menu_admin from './paper/main/menu_admin'

import Main from './paper/main'
import Login from './paper/login'
import Pos_main from './paper/pos/pos_main'

import Create_add_stock from './paper/stock/create_add_stock'
import Create from './paper/stock/create'
import Chang from './paper/stock/edit_del_stock'
import Tranfer from './paper/stock/tranfer_stock'

import Report_today from './paper/report/report_today'
import Report_fit from './paper/report/report_fit'
import End_order_point from './paper/report/end_order_point'
import Cost from './paper/report/cost'

import Profile from './paper/profile/profile'
import Security from './paper/profile/security'
import Privatcy from './paper/profile/privatcy'
import Account from './paper/profile/account'

import { AnimatePresence, motion } from 'framer-motion'
import bg from './img/Gradient.jpg'
import { useMediaQuery } from 'react-responsive'
import axios from 'axios';
import Header from './paper/sidebar'

import logo from './online-shopping.png';
function App() {
  const history = useHistory()

  const [show, setShow] = useState(true)

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <>
      <BrowserRouter basename='/demo'>
        <div style={{
          backgroundColor: "#1769aa",
          height: '330px',
          borderRadius: '0px 0px 0px 0px'
        }}
        >

          <Switch>
            <Route path='/' exact>
              <Login />
            </Route>

            <Route path='/main'>
              <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2'>
                    <Main />
                  </motion.div>
                </div>
              </div>
            </Route>

            <Route path='/sell'>
              <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10" style={{height:'100vh',overflow:'scroll'}}>
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} >
                    <Pos_main />
                  </motion.div>
                </div>
              </div>
            </Route>

            <Route path='/stock/addStock'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2'>
                <Create_add_stock />
              </motion.div>
              </div>
              </div>
            </Route>

            <Route path='/stock/newProduct'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2'>
                <Create />
              </motion.div>
              </div>
              </div>
            </Route>

            <Route path='/stock/changeProduct'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2'>
                <Chang />
              </motion.div>
              </div>
              </div>
            </Route>

            {/* <Route path='/stock/tranferProduct'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2'>
                <Tranfer />
              </motion.div>
              </div>
              </div>
            </Route> */}

            <Route path='/report/toDay'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2' style={{height:'100vh',overflow:'scroll'}}>
                <Report_today />
              </motion.div>
              </div>
              </div>
            </Route>

            <Route path='/report/dayCustome'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2' style={{height:'100vh',overflow:'scroll'}}>
                <Report_fit />
              </motion.div>
              </div>
              </div>
            </Route>

            <Route path='/report/productEndPoint'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2' style={{height:'100vh',overflow:'scroll'}}>
                <End_order_point />
              </motion.div>
              </div>
              </div>
            </Route>

            <Route path='/report/CostAllCheck'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2'>
                <Cost />
              </motion.div>
              </div>
              </div>
            </Route>

            <Route path='/account/store'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2'>
                <Profile />
              </motion.div>
              </div>
              </div>
            </Route>

            <Route path='/account/security'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2'>
                <Security />
              </motion.div>
              </div>
              </div>
            </Route>

            <Route path='/account/account'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2'>
                <Account />
              </motion.div>
              </div>
              </div>
            </Route>

            <Route path='/account/private'>
            <div className="d-flex">
                <div className="col-xl-2" style={{ height: '100vh', background: 'white', boxShadow: '0px 1px 2px silver' }}>
                  <Header />
                </div>
                <div className="col-xl-10">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='p-2'>
                <Privatcy />
              </motion.div>
            </div>
            </div>
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </>
  );
}


export default App;
