import React, { useState, useEffect } from 'react'
import render from 'react-dom'
import { Container, Row, Col, Card, Table, Modal, Button } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'
import md5 from 'md5'

const Form_Account = () => {
    const { account } = useParams()
    const [emp_name, setEmp_name] = useState('')
    const [emp_user, setEmp_user] = useState('')
    const [emp_phone, setEmp_phone] = useState('')
    const [pass_org, setPass_org] = useState('')
    const [pass_new, setPass_new] = useState('')
    const [name_status, setName_status] = useState('')
    const get = () => axios.post('http://apichatapi.ddns.net/api/pos/get_account_in_store.php', { store_id: localStorage.getItem('store_id'), emp_user: account }).then(res => {
        if (res.data.length > 0) {
            res.data.map(item => {
                setEmp_user(item.emp_user)
                setEmp_name(item.emp_name)
                setEmp_phone(item.emp_phone)
                setPass_org(item.emp_password)
                setName_status(item.name_status)
            })
        }
    })

    useEffect(() => {
        get()
    }, [])

    const update = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/update_account.php',
            {
                store_id: localStorage.getItem('store_id'),
                emp_name: emp_name,
                emp_user: emp_user,
                emp_phone: emp_phone,
                pass_org: pass_org,
                pass_new: pass_new,
                name_status: name_status
            }).then(res => {
                if (res.data.status === 200) {
                    alert('อัพเดทข้อมูลสำเร็จ')
                }
            })
    }

    return (
        <>
            <Link to={`/account/security`} style={{ textDecoration: 'none', marginLeft: '10px', color: 'white' }}> <span className='bi bi-arrow-left-circle'></span> เลือกบัญชีที่ต้องการเปลี่ยนแปลงข้อมูล</Link>
            <div className="App-header-light">
                <div className='col-xl-4 p-2'>
                    <Modal.Dialog>
                        <Modal.Header>
                            <Modal.Title style={{ fontSize: '20px' }}>เปลี่ยนแปลงข้อมูล</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Table borderless>
                                <tbody>
                                    <tr align="center" className='text-header'>
                                        <td><h5><span className='fas fa-user-cog'></span> </h5></td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <label htmlFor="">ชื่อ - สกุล</label>
                                            <input type="text" className='form-control input_box mt-2' value={emp_name} onChange={(e) => setEmp_name(e.target.value)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="">ชื่อผู้ใช้</label>
                                            <input type="text" className='form-control input_box mt-2' value={emp_user} onChange={(e) => setEmp_user(e.target.value)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="">รหัสผ่านใหม่</label>
                                            <input type="password" className='form-control input_box mt-2' value={pass_new} onChange={(e) => { setPass_new(e.target.value) }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label htmlFor="">เบอร์โทรศัพท์</label>
                                            <input type="tel" className='form-control input_box mt-2' value={emp_phone} onChange={(e) => setEmp_phone(e.target.value)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align='center'>
                                            <Button className='col-xl-7 col-12 border-0' style={{ background: 'rgb(33, 150, 243)', boxShadow: '0px 1px 2px silver' }} onClick={() => update()}>บันทึกการเปลี่ยนแปลง</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Modal.Body>
                    </Modal.Dialog>
                </div>
            </div>
        </>
    )
}

const Profile = () => {
    const { url } = useRouteMatch()
    const [data, setData] = useState([])

    const get = () => axios.post('http://apichatapi.ddns.net/api/pos/get_account_in_store_all.php', { database: localStorage.getItem('database'), store_id: localStorage.getItem('store_id') }).then(res => {
        if (res.data.length > 0) {
            setData(res.data)
        }
    })

    const Check_status = () => {
        const status = localStorage.getItem('name_status')
        if (status === 'user') {
            return (
                <>
                    <Form_Account />
                </>
            )
        } else if (status === 'admin') {
            return (
                <>
                    <Form_Account />
                </>
            )
        }
    }

    useEffect(() => {
        get()
    }, [])

    return (
        <>
            <BrowserRouter basename='/demo'>
                <Switch>
                    <Route path={`${url}`} exact>
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '80vh' }}>
                                <Container>
                                    <center>
                                        {/* <div className='m-3 mb-2 text-header ' style={{ fontSize: '20px', color: 'white' }}><span className='fas fa-lock'></span> </div> */}
                                        <div className='col-10 col-lg-4'>

                                            <Modal.Dialog>
                                                <Modal.Header>
                                                    <Modal.Title style={{ fontSize: '20px' }}>รักษาความปลอดภัย</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className='' style={{ fontSize: '14px', marginTop: '10px', marginLeft: '10px', color: '#8E8E8E' }}>เลือกบัญชีที่ต้องการเปลี่ยนแปลงข้อมูล</div>
                                                    <ul class="list-group list-group-flush mt-1">
                                                        {
                                                            data.map(convert => {
                                                                if (localStorage.getItem('name_status') === 'user') {
                                                                    if (convert.emp_name === localStorage.getItem('pos_name')) {
                                                                        return (
                                                                            <Link to={`${url}/${convert.emp_user}`} style={{ textDecoration: 'none' }}>
                                                                                <li class="list-group-item list-group-item-action border-0">
                                                                                    {convert.emp_name}
                                                                                </li>
                                                                            </Link>
                                                                        )
                                                                    }

                                                                } else if (localStorage.getItem('name_status') === 'admin') {
                                                                    return (
                                                                        <Link to={`${url}/${convert.emp_user}`} style={{ textDecoration: 'none' }}>
                                                                            <li class="list-group-item list-group-item-action border-0">
                                                                                {convert.emp_name}
                                                                            </li>
                                                                        </Link>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </ul>
                                                </Modal.Body>
                                            </Modal.Dialog>

                                        </div>
                                    </center>
                                </Container>
                            </div>
                        </>
                    </Route>
                    <Route path={`${url}/:account`}>
                        <Check_status />
                    </Route>
                </Switch>
            </BrowserRouter>
        </>
    )
}

export default Profile