import React, { useState, useEffect } from 'react'
import render from 'react-dom'
import { Container, Row, Col, Card, Table, Modal, Button, Form } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'

function Cost() {
    const [data, setData] = useState([])
    var a = 0
    var b = 0
    var c = a - b
    
    const search = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/item_all.php', { database: localStorage.getItem('database') }).then(res => {
            if (res.data.length > 0) {
                setData(res.data)
            }
        })
    }

    useEffect(() => {
        search()
    }, 1)

    return (
        <>
            <div className="App-header-light">
                <div className='text-center col-xl-4 pb-5 mt-3 bg-white'>
                    <Table style={{ boxShadow: '0px 1px 2px 0px #7a7a7a' }} borderless>
                        <thead style={{color:'#1769aa'}}>
                            <tr>
                            <th colSpan='2' className='' style={{fontSize:'18px'}}> รายงานต้นทุนสินค้า</th>
                            </tr>
                        </thead>
                        {
                            data.map(item => {
                                var stock = item.stock_quantity
                                a += +item.cost * stock;
                                b += +item.price * stock;
                                c += (item.price - item.cost) * stock;
                            })
                        }
                        <tbody>
                            <tr>
                                <td>ต้นทุนสินค้า</td>
                                <td>{parseFloat(a).toFixed(2)} .-</td>
                            </tr>
                            <tr>
                                <td>ราคาสินค้า</td>
                                <td>{parseFloat(b).toFixed(2)} .-</td>
                            </tr>
                            <tr>
                                <td>กำไร</td>
                                <td>{parseFloat(c).toFixed(2)} .-</td>
                            </tr>
                        
                    </tbody>
                </Table>
            </div>
        </div>
        </>
    )
}

export default Cost