import React, { useState, useEffect } from 'react'
import render from 'react-dom'
import { Container, Row, Col, Card, Table, Modal, Button, Form } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'

const Profile = () => {
    const [store_name, setStore_name] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')

    const get = () => axios.post('http://apichatapi.ddns.net/api/pos/get_store.php', { database: localStorage.getItem('database'), store_id: localStorage.getItem('store_id') }).then(res => {
        if (res.data.length > 0) {
            res.data.map(item => {
                setStore_name(item.store_name)
                setAddress(item.address)
                setPhone(item.phone)
            })
        }
    })

    const update = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/store_update.php',
            {
                store_id: localStorage.getItem('store_id'),
                store_name: store_name,
                address: address,
                phone: phone
            }).then(res => {
                if (res.data.status === 200) {
                    alert('อัเดทข้อมูลสำเร็จ')
                }
            })
    }

    useEffect(() => {
        get()
    }, [])
    return (
        <>
            <div className="App-header-light">
                <div className='col-md-5 col-lg-5 col-xl-4 p-2'>
                    <Modal.Dialog>
                        <Modal.Header>
                            <Modal.Title className='h5'>ข้อมูลร้านค้า</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                        <Table borderless>
                        <tbody>
                            <tr align="center">
                                <td className='text-header' style={{ fontSize: '18px' }}></td>
                            </tr>

                            <tr>
                                <td>
                                    <label htmlFor="">ชื่อร้าน</label>
                                    <input type="text" className='form-control input_box mt-2' value={store_name} onChange={(e) => setStore_name(e.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="">ที่อยู่</label>
                                    <textarea type="text" className='form-control input_box mt-2' value={address} onChange={(e) => setAddress(e.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="">เบอร์โทรศัพท์</label>
                                    <input type="tel" className='form-control input_box mt-2' value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td align='center'>
                                    <Button className='col-xl-7 col-12 border-0' style={{ background: 'rgb(33, 150, 243)', boxShadow: '0px 1px 2px silver' }} onClick={() => update()}>บันทึกการเปลี่ยนแปลง</Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                        </Modal.Body>
                    </Modal.Dialog>
                </div>
            </div>
        </>
    )
}

export default Profile