import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Table, Modal, Button, Alert } from 'react-bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import date from 'date-and-time';
import Clock from 'react-live-clock';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import logo from '../online-shopping.png';

function Login() {
    const history = useHistory();
    const [user, setUser] = useState('')
    const [pass, setPass] = useState('')
    const send = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/login.php', { user: user, pass: pass }).then(res => {
            if (res.data.length > 0) {
                res.data.map(row => {
                    localStorage.setItem('pos_name', row.emp_name)
                    localStorage.setItem('name_status', row.name_status)
                    localStorage.setItem('database', 'data_store_' + row.store_id)
                    localStorage.setItem('store_id', row.store_id)
                    localStorage.setItem('status', row.emp_status)
                    localStorage.setItem('emp_user', row.emp_user)
                })
                if (localStorage.getItem('pos_name')) {
                    history.push('/main')
                }
            } else {
                alert('ไม่สามารถเข้าสู่ระบบได้ กรุณาตรวจสอบ')
            }
        })
    }

    useEffect(() => {
        localStorage.setItem('pos_name', '')
        localStorage.setItem('name_status', '')
        localStorage.setItem('database', '')
        localStorage.setItem('store_id', '')
        localStorage.setItem('status', '')
        localStorage.setItem('emp_user', '')
    })
    return (
        <>
            <motion.div
                animate={{ x: 0 }}
                transition={{ type: "spring", stiffness: 100, x: 10 }}
            >
                <div className="App-header-light">
                    <div className='col-10 col-md-5 col-lg-5 col-xl-3 bg-white p-4' style={{borderRadius:'10px',boxShadow:'0px 1px 2px silver'}}>
                        <center><img src={logo} style={{ maxWidth: '120px',height: '130px'}} /></center>
                        <p></p>
                        <div className="">
                            <label htmlFor="">ชื่อบัญชี / Username</label>
                            <input type="text" className="form-control mt-1" id='user' required
                                onChange={(e) => { setUser(e.target.value) }}
                                onKeyUp={(e) => {
                                    if (e.keyCode === 13) {
                                        document.querySelector("#pass").focus()
                                    }
                                }
                                }
                            />
                        </div>
                        <div className="mt-3">
                            <label htmlFor="">รหัสผ่าน / Password</label>
                            <input type='password' className="form-control mt-1" id='pass'
                                onChange={(e) => { setPass(e.target.value) }}
                                onKeyUp={(e) => {
                                    if (e.keyCode === 13) {
                                        if (user !== '' && pass !== '') {
                                            send()
                                        } else if (user === '') {
                                            document.querySelector("#user").focus()
                                        } else if (pass === '') {
                                            document.querySelector("#pass").focus()
                                        }
                                    }
                                }
                                }
                            />
                        </div>
                        <div className="mt-4">
                            <button className="btn btn-primary col-12" onClick={() => send()}>เข้าสู่ระบบ</button>
                        </div>
                    </div>
                </div>
                <div className='text-center' style={{ color: 'silver' }}>
                    <small className='text-center'>
                        เวอร์ชั่น 7.1.0 | versions 7.1.0 &nbsp;&nbsp; © ลิขสิทธิ ร้านอภิชาติ มินิมาร์ท
                    </small>
                </div>
            </motion.div>
        </>
    )
}

export default Login;