import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Table, Modal, Form, DropdownButton, Dropdown, Offcanvas, Spinner, Tabs, Tab, Button } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, Prompt } from 'react-router-dom'
import axios from 'axios'
import '../../App.css'
import Select_bill from './select_bill'
import date from 'date-and-time';
import Pos_one from './pos_one'


function Pos_main(props) {
    return (
        <div className="p-2 bg-white container col-sm-12 col-md-11 col-lg-11 col-xl-12" style={{height:'100vh',width: '82vw' }}>
            <Tabs defaultActiveKey="page1" className="mb-3" >
                <Tab eventKey="page1" title="ตะกร้าสินค้า 1">
                    <Pos_one />
                </Tab>
                <Tab eventKey="page2" title="ตะกร้าสินค้า 2">
                    <Pos_one />
                </Tab>
                <Tab eventKey="page3" title="ตะกร้าสินค้า 3">
                    <Pos_one />
                </Tab>
            </Tabs>
        </div>
    )

}

const styles = {
    table_list: {
        textAlign: 'center',
        marginTop: '20px',
    },
    count: {
        boxShadow: '0px 1px 2px silver',
    },
    left: {
        height: '80vh',
        overflow: 'scroll',
    },
    left_cash: {
        height: 'auto',
        overflow: 'scroll',
    },
    bill_detail: {
        overflow: 'scroll',
        height: '80vh',
    },

}

export default Pos_main;
