import React, { useState, useEffect } from 'react'
import render from 'react-dom'
import { Container, Row, Col, Card, Table, Modal, Button, Form } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'

function End_order_point() {
    const [data, setData] = useState([])
    const get_order_end_point = () => axios.post('http://apichatapi.ddns.net/api/pos/order_end_point.php', { database: localStorage.getItem('database') })
        .then(res => {
            setData(res.data)
        })
    useEffect(() => {
        get_order_end_point()
    }, [])
    return (
        <>
        <div className="container" style={styles.flex_advance}>
                <div className='text-center pt-3' style={{ fontSize: '20px',color:'white' }}>
                    สินค้าถึงจุดสั่งซื้อ
                </div>
            <div className='text-center mt-3 table-responsive' style={{ boxShadow: '0px 1px 2px silver' }}>
                <Table style={{ boxShadow: '0px 1px 2px silver',background:'white' }} borderless hover>
                    <thead className='table-light' style={{ boxShadow: '0px 1px 2px silver', borderTop: '5px solid rgb(23, 105, 170)',position:'sticky',top: '0px'}}>
                        <tr>
                            <th>บาร์โค้ด</th>
                            <th>รายการสินค้า</th>
                            <th>ราคาสินค้า</th>
                            <th>ต้นทุนสินค้า</th>
                            <th>จำนวนสินต้า</th>
                            <th>หน่วย</th>
                            <th>ปรเภท</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map(data => {
                                return (
                                    <tr>
                                        <td>{data.pro_barcode}</td>
                                        <td>{data.pro_name}</td>
                                        <td>{data.price}</td>
                                        <td>{data.cost}</td>
                                        <td>{data.stock_quantity}</td>
                                        <td>{data.unit}</td>
                                        <td>{data.cate}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            </div>
        </>
    )
}

const styles = {
    flex_advance: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'center',
        marginTop:'10px',
        borderRadius:'10px',
        // backgroundColor: 'white',
    }
}

export default End_order_point