import React, { Component, useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Card, Table, Modal, Button, Form } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, useParams, useHistory } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import axios from 'axios'
import '../../App.css'

function Select_bill() {
    const { bill_id } = useParams()
    const [bill_detail_order_only, setBill_detail_order_only] = useState([])
    const [total_price, setTotal_price] = useState(0) //ราคารวม
    const [drop, setDrop] = useState(0) // ส่วนลด
    const [payment, setPayment] = useState(0) // รับเงินมา
    const [net_price, setNet_price] = useState(0) // ราคาสุทธิ
    const [date, setDate] = useState('')

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [store_name , setStore_name] = useState('')
    const [address , setAddress] = useState('')
    const [phone , setPhone] = useState('')
    
    const get = () => axios.post('http://apichatapi.ddns.net/api/pos/get_store.php',{database: localStorage.getItem('database'),store_id: localStorage.getItem('store_id')}).then(res=>{
        if(res.data.length > 0){
            res.data.map(item => {
                setStore_name(item.store_name)
                setAddress(item.address)
                setPhone(item.phone)
            })
        }
    })

    function Fetch_s() {
        axios.post('http://apichatapi.ddns.net/api/pos/bill_select.php',{bill_id: bill_id , database : localStorage.getItem('database')})
            .then(res => {
                setBill_detail_order_only(res.data.bill_detail)
                setTotal_price(res.data.total_price)
                setDrop(res.data.discount)
                setPayment(res.data.payment)
                setNet_price(res.data.net_price)
                setDate(res.data.date)
                // console.log(res)
            }).catch(err => {
                // console.log(err)
            })
    }
    useEffect(() => {
        Fetch_s()
        get()
    }, [bill_id])

    function Fetch_bill_detail() {
        return (
            <Container className='mt-1 pt-3 pb-1' style={{ background: 'white', width: '297px', fontSize: '14px', paddingLeft: '1px', paddingRight: '0px', boxShadow: '0px 1px 2px silver' }}>
                <div id='silp'>
                <center>
                    <b>
                        {store_name}<br />
                        {address} <br></br>
                        -------------------------------------------------------------
                    </b>
                </center>

                {
                    bill_detail_order_only.map((item, index) => {
                        return (
                            <>
                                <li style={{ listStyle: 'none', paddingLeft: '10px' }}>{item.pro_name} x {item.count} <span className='float-end' style={{ paddingRight: '10px' }}>{item.total_price}</span></li>
                                {/* <li style={{ listStyle: 'none', paddingLeft: '10px' }}>{item.pro_name} x {item.quantity} <span className='float-end' style={{ paddingRight: '10px' }}>{item.total_price}</span></li> */}
                            </>
                        )
                    })
                }

                <b>
                    <li style={{ listStyle: 'none', paddingLeft: '10px', marginTop: '10px' }}>
                        ราคารวม
                        <span className='float-end' style={{ paddingRight: '10px' }}>{total_price}</span>
                    </li>
                    <li style={{ listStyle: 'none', paddingLeft: '10px' }}>
                        ส่วนลด
                        <span className='float-end' style={{ paddingRight: '10px' }}>{drop}</span>
                    </li>
                    <li style={{ listStyle: 'none', paddingLeft: '10px' }}>ราคาสุทธิ
                        <span className='float-end' style={{ paddingRight: '10px' }}>{net_price}</span>
                    </li>
                    <li style={{ listStyle: 'none', paddingLeft: '10px', marginTop: '10px' }}>รับเงิน
                        <span className='float-end' style={{ paddingRight: '10px' }}>{payment}</span>
                    </li>
                    <li style={{ listStyle: 'none', paddingLeft: '10px' }}>ทอน
                        <span className='float-end' style={{ paddingRight: '10px' }}>{payment - net_price}</span>
                    </li>
                </b>

                <b>-------------------------------------------------------------</b>
                <div className='text-center'>
                    บิลเลขที่ {bill_id} <br />
                    {date}
                </div>
                </div>
            </Container>
        )
    }

    function print_slip() {
        window.open("http://apichatapi.ddns.net/api/pos/print_slip.php?bill_id=" + bill_id + "&store_id="+localStorage.getItem("store_id") + "&database="+ localStorage.getItem('database'), "_blank", "width=300,height=1000")
    }

    function print_a4(name, address) {
        window.open("http://apichatapi.ddns.net/api/pos/print_a4.php?bill_id=" + bill_id + "&store_id="+localStorage.getItem("store_id") + "&database="+ localStorage.getItem('database'), "_blank", "width=1024,height=768")
    }

    return (
        <>
            <div className='sticky-top'>
                <center>
                    <div className='btn-group mt-1 text-center'>
                        <button className='btn btn-outline-secondary shadow-sm pt-1 pb-1' onClick={() => print_slip()}><span className='fas fa-print'></span> Slip</button>
                        <button className='btn btn-outline-secondary shadow-sm pt-1 pb-1' onClick={() => print_a4()}><span className='fas fa-file'></span> Receipt </button>
                        {/* <button className='btn btn-secondary shadow-sm pt-1 pb-1'><span className='fas fa-share'></span></button> */}
                    </div>
                </center>
                <div id='bill' className='mt-4'>
                    <Fetch_bill_detail />
                </div>
                
            </div>
        </>
    )
}




export default Select_bill