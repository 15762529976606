import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Table, Modal, Button } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'
import md5 from 'md5'
import Form_features from './form_privatcy'

const Profile = () => {
    const { url } = useRouteMatch()
    const [data, setData] = useState([])

    const get = () => axios.post('http://apichatapi.ddns.net/api/pos/get_account_in_store_all.php', { database: localStorage.getItem('database'), store_id: localStorage.getItem('store_id') }).then(res => {
        if (res.data.length > 0) {
            setData(res.data)
        }
    })

    const Check_status = () => {
        const status = localStorage.getItem('name_status')
        if (status === 'user') {
            return (
                <>
                    <Form_features />
                </>
            )
        } else if (status === 'admin') {
            return (
                <>
                    <Form_features />
                </>
            )
        }
    }

    useEffect(() => {
        get()
    }, [])

    return (
        <>
            <BrowserRouter basename='/demo'>
                <Switch>
                    <Route path={`${url}`} exact>
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '80vh' }}>
                                {/* <div className='m-3 mb-2 text-header' style={{ fontSize: '20px' }}><span className='fas fa-key'></span> </div> */}
                                <div className='col-10 col-lg-4'>
                                    <Modal.Dialog>
                                        <Modal.Header>
                                            <Modal.Title style={{fontSize:'20px'}}>กำหนดสิทธิ์การเข้าถึง</Modal.Title>
                                        </Modal.Header>

                                        <Modal.Body>
                                        <div className='' style={{ fontSize: '14px', marginTop: '10px', marginLeft: '10px', color: '#8E8E8E' }}>เลือกบัญชีที่ต้องการเปลี่ยนแปลงสิทธิการเข้าถึง</div>
                                    <ul class="list-group list-group-flush mt-1">
                                        {
                                            data.map(convert => {
                                                if (localStorage.getItem('name_status') === 'user') {
                                                    if (convert.emp_name === localStorage.getItem('pos_name')) {
                                                        return (
                                                            <Link to={`${url}/${convert.emp_user}`} style={{ textDecoration: 'none' }}>
                                                                <li class="list-group-item list-group-item-action border-0">
                                                                    {convert.emp_name}
                                                                </li>
                                                            </Link>
                                                        )
                                                    }

                                                } else if (localStorage.getItem('name_status') === 'admin') {
                                                    return (
                                                        <Link to={`${url}/${convert.emp_user}`} style={{ textDecoration: 'none' }}>
                                                            <li class="list-group-item list-group-item-action border-0">
                                                                {convert.emp_name}
                                                            </li>
                                                        </Link>
                                                    )
                                                }
                                            })
                                        }
                                    </ul>
                                        </Modal.Body>
                                    </Modal.Dialog>

                                </div>
                            </div>
                        </>
                    </Route>
                    <Route path={`${url}/:account`}>
                        <Check_status />
                    </Route>
                </Switch>
            </BrowserRouter>
        </>
    )
}

export default Profile