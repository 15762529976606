import React, { useState, useEffect } from 'react'
import render from 'react-dom'
import { Container, Row, Col, Card, Table, Modal, Button, Form } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, Redirect, useParams, useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'

function Tranfer_form() {

    const [page, setPage] = useState(0)
    const [product, setProduct] = useState([])
    const { id, store_name } = useParams()
    const { url, path } = useRouteMatch()
    const [barcode_orginal, setBarcode_orginal] = useState('')
    const [barcode, setBarcode] = useState('')
    const [pro_name, setPro_name] = useState('')
    const [cate, setCate] = useState('')
    const [cost, setCost] = useState('')
    const [price, setPrice] = useState('')
    const [stock, setStock] = useState('')
    const [end_point, setEnd_point] = useState('')
    const [stock_client,setStock_client] = useState('')

    const [tranfer_stock, setTranfer_stock] = useState(0)
    // const [key, setKey] = useState('')

    const search = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/item_all.php', { database: localStorage.getItem('database') }).then(res => {
            setProduct(res.data)
        })
    }
    useEffect(() => {
        search()
    }, [])

    function Tranfer() {
        return (
            <>
                <div className='d-flex justify-content-center'>
                    <div className='col-xl-5 col-12 mt-4 p-2' >
                        <Table borderless size='sm' style={{ marginTop: '20px' }}>
                            <tbody style={{ verticalAlign: 'middle' }}>
                                <tr >
                                    <td>รหัสสินค้า</td>
                                    <td>{barcode}</td>
                                </tr>
                                <tr>
                                    <td>ชื่อสินค้า</td>
                                    <td>{pro_name}</td>
                                </tr>
                                <tr>
                                    <td>ประเภท</td>
                                    <td>{cate}</td>
                                </tr>
                                <tr>
                                    <td>ต้นทุนสินค้า</td>
                                    <td>{cost}</td>
                                </tr>
                                <tr>
                                    <td>ราคาสินค้า</td>
                                    <td>{price}</td>
                                </tr>
                                <tr>
                                    <td>จำนวนคงเหลือ</td>
                                    <td>{stock} &nbsp;&nbsp; {product.unit}</td>
                                </tr><br />
                                <tr>
                                    <td colSpan='2' align='center' className='bg-light text-secondary'>โอนไปยัง &nbsp;&nbsp;<span className="bi bi-arrow-right" />&nbsp;&nbsp; {store_name}</td>
                                </tr>

                                <tr>
                                    <td align='center' style={{ paddingTop: '10px' }}>จำนวนที่ต้องการโอน</td>
                                    <td><input type="text" style={{ marginTop: '10px' }} className='form-control input_line col-xl-3' style={{ width: '200px' }}
                                        onChange={(e) => {
                                            setTranfer_stock(e.target.value)
                                        }} /></td>
                                </tr>
                            </tbody>
                        </Table>
                        <center>
                            <Button className='col-12 border-0 col-xl-5' style={{ boxShadow: '0px 1px 2px silver', backgroundColor: '#2196f3' }} onClick={() => { prepare() }}>เปลี่ยนแปลงข้อมูลสินค้า</Button>
                        </center>
                        <p></p>
                    </div>
                </div>
            </>
        )
    }

    const Resert = () => {
        if (page === 1) {
            return Tranfer()
        } else {
            return (
                <>
                    <div style={{ textAlign: 'center', color: 'silver', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: 'calc(16px + 2vmin)', minHeight: '70vh' }}>
                        <div className="bi bi-arrow-left-right"></div> โอนสินค้าระหว่างสาขา
                    </div>
                </>
            )

        }
    }

    // สร้างรายการสินค้า
    const create = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/create.php',
        {
            barcode : barcode,
            pro_name: pro_name,
            type : cate,
            cost: cost,
            price: price,
            count: stock,
            end_point: end_point,
            database : 'data_store_'+ id
        }).then(res=>{
            // console.log(res.data);  
        }).catch(err => {
            alert(err)
        })
    }

    // อัพเดทข้อมูลปลายทาง
    const update_t = (props) => {
        var stock_t = +props.quantity + +tranfer_stock
        axios.post('http://apichatapi.ddns.net/api/pos/update_tranfer_stock.php',
        {
            barcode_orginal: barcode_orginal,
            barcode: barcode,
            pro_name: pro_name,
            cate: cate,
            cost: cost,
            price: price,
            stock: stock_t,
            end_point: end_point,
            database: 'data_store_'+id
        }).then(res => {
            if (res.data.status === 200) {
            }
        }).catch((err) => {
            alert(err)
        })
    }

    // อัพเดทข้อมูลต้นทาง
    const update = () => {
        var total = +stock - +tranfer_stock
        // อัพเดทสินค้าฝั่ง ผู้ส่ง
        axios.post('http://apichatapi.ddns.net/api/pos/update_data_stock.php',
            {
                barcode_orginal: barcode_orginal,
                barcode: barcode,
                pro_name: pro_name,
                cate: cate,
                cost: cost,
                price: price,
                stock: total,
                end_point: end_point,
                database: localStorage.getItem('database')
            }).then(res => {
                // console.log(res.data)
                if (res.data.status == 200) {
                    alert('โอนย้ายข้อมูลสินค้าสำเร็จ')
                    search()
                    setPage(0)
                    Resert()
                    setTranfer_stock(0)
                }
            }).catch((err) => {
                alert(err)
            })
    }

    const prepare = () => {
        // รับจำนวนสินค้า -> อัพเดทสินค้าฝั่ง ผู้รับ
        axios.post('http://apichatapi.ddns.net/api/pos/get_stock_client.php', { database: 'data_store_' + id , barcode:barcode}).then(res => {
            if (res.data.length !== 0) {
                res.data.map(pro => {
                    setStock_client(pro.stock_quantity)
                    update_t({quantity:pro.stock_quantity})
            })
                update()
            }
            else {
                create()
                update()
            }
        })
    }


    const Select_form = () => {
        return (
            <div>
                <Row className="justify-content-center mt-3">
                    <Col xs={12} xl={4}>

                        <input type="text" class="form-control text-center" list="seach" placeholder="กรอกบาร์โค้ด / ชื่อสินค้าที่ต้องการ" style={styles.form}
                            onKeyUp={(e) => {
                                if (e.keyCode === 13) {
                                    // setKey(e.target.value)
                                    // console.log(tranfer_stock)
                                    Feed({ keys: e.target.value })
                                }
                            }}
                        />

                    </Col>
                </Row>
                <datalist id='seach' style={styles.datalist}>
                    {
                        product.map((product, index) => {
                            return (
                                <option key={index} value={product.pro_barcode}>{product.pro_barcode} ::: {product.pro_name}</option>
                            )
                        })
                    }
                </datalist>
            </div >
        )
    }

    const Feed = (props) => {
        const find = product.filter(product => product.pro_barcode === props.keys)
        find.map((product, index) => {
            setBarcode_orginal(product.pro_barcode)
            setBarcode(product.pro_barcode)
            setPro_name(product.pro_name)
            setCate(product.cate)
            setCost(product.cost)
            setPrice(product.price)
            setStock(product.stock_quantity)
            setEnd_point(product.order_point)
        })
        if (find.length > 0) {
            setPage(1)
        }
    }
    return (
        <>
            <Link to={`/stock/tranferProduct`} onClick={() => setPage(0)} style={{ textDecoration: 'none', marginLeft: '10px' }}> <span className='bi bi-arrow-left-circle'></span> เลือกสาขาที่ต้องการโอนสินค้า</Link>
            <Select_form />
            {Resert()}
        </>
    )
}

const styles = {
    form: {
        boxShadow: '0px',
        boxShadow: '0px 1px 2px silver',
        borderRadius: '50px',
        // width: '400px',
    },
    select: {
        display: 'flex',
        flexDirection: 'column',
        AlignItems: 'center',
        justifyContent: 'center',
        textAlignLast: 'center',
        marginTop: '30px'
    },
}

export default Tranfer_form;