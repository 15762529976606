import React, { useState, useEffect } from 'react'
import render from 'react-dom'
import { Container, Row, Col, Card, Table, Modal, Button, Form , Navbar } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, Redirect, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'
import '../../App.css'
import Create from './create'

const Desktop = () => {
    const history = useHistory()
    const { url, path } = useRouteMatch()
    const [product, setProduct] = useState([])
    const [key, setKey] = useState('')
    const [page, setPage] = useState(0)

    const load_products = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/item_all.php',{database : localStorage.getItem('database')}).then(res => {
            setProduct(res.data)
        })
    }

    useEffect(() => {
        load_products()
    }, [])

    const Select_form = () => {
        return (
            <div>
                <div className="d-flex justify-content-center mt-3 pt-4">
                    <Col xs={12} xl={4}>

                        <input type="text" class="form-control text-center" list="seach" placeholder="กรอกบาร์โค้ด / ชื่อสินค้าที่ต้องการ" style={styles.form}
                            onKeyUp={(e) => {
                                if (e.keyCode === 13) {
                                    setKey(e.target.value)
                                    Feed({ keys: e.target.value })
                                }
                            }}
                        />
                    </Col>
                </div>
                <div style={{ position: 'fixed', bottom: '50px', right: '50px', textAlign: 'center' }}>
                    <Navbar fixed="bottom" bg="light" align='center' variant="light">
                        <Button className="btn btn-light" onClick={() => { setPage(2) }}> สร้างรายการสินค้าใหม่</Button>
                    </Navbar>
                </div>
                <datalist id='seach' style={styles.datalist}>
                    {
                        product.map((product, index) => {
                            return (
                                <option key={index} value={product.pro_barcode}>{product.pro_barcode} ::: {product.pro_name}</option>
                            )
                        })
                    }
                </datalist>
            </div >
        )
    }

    const update = (count, pro_barcode, quantity) => {
        var total = +count + +quantity
        axios.post('http://apichatapi.ddns.net/api/pos/update_stock.php', { pro_barcode: pro_barcode, quantity: total , database : localStorage.getItem('database')}).then(res => {
            if (res.data.status === 200) {
                load_products()
            }
        }).catch(err => {
            alert(err)
        })
    }

    const Add = () => {
        const find = product.filter(product => product.pro_barcode === key | product.pro_name === key)
        return (
            <>
                {
                    find.map((product, index) => {
                        return (
                            <>
                                <Row className='justify-content-center'>
                                    <div className='col-lg-5 col-12 mt-4'>
                                        <Table borderless size='sm' style={{ marginTop: '20px' }}>
                                            <tbody style={{ verticalAlign: 'middle' }}>
                                                <tr >
                                                    <td>รหัสสินค้า</td>
                                                    <td>{product.pro_barcode}</td>
                                                </tr>
                                                <tr>
                                                    <td>ชื่อสินค้า</td>
                                                    <td>{product.pro_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>ประเภท</td>
                                                    <td>{product.cate}</td>
                                                </tr>
                                                <tr>
                                                    <td>ต้นทุนสินค้า</td>
                                                    <td>{product.cost}</td>
                                                </tr>
                                                <tr>
                                                    <td>ราคาสินค้า</td>
                                                    <td>{product.price}</td>
                                                </tr>
                                                <tr>
                                                    <td>จำนวนคงเหลือ</td>
                                                    <td>{product.stock_quantity} &nbsp;&nbsp; {product.unit}</td>
                                                </tr>
                                                <br />
                                                <tr>
                                                    <td>รับสินค้าจำนวน</td>
                                                    <td><input type="text" className='form-control input_line' placeholder='จำนวนสินค้า' style={{ fontSize: '18px', textAlign: 'center' , width:'200px' }} id='count' /> </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <center>
                                        <Button className='col-12 col-xl-6 border-0'
                                            onClick={() => {
                                                var pro_barcode = product.pro_barcode
                                                var count = document.querySelector('#count').value
                                                var quantity = product.stock_quantity
                                                update(count, pro_barcode, quantity)
                                            }} style={{backgroundColor:'#2196f3',boxShadow:'0px 1px 2px silver'}}>รับสินค้าเข้าคลัง</Button>
                                            </center>
                                        <p></p>
                                    </div>
                                </Row>
                            </>
                        )
                    })
                }
            </>
        )
    }

    const Resert = () => {
        if (page === 1) {
            return <Add />
        } else if (page === 2) {
            return <Create />
        } else if (page === 0) {
            return (
                <>
                    <div style={{ textAlign: 'center', color: 'silver', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: 'calc(16px + 2vmin)', minHeight: '70vh' }}>
                        <div className="bi bi-cloud-download"></div> รับสินค้าเข้าคลังสินค้า
                    </div>
                </>

            )
        }
    }

    const Feed = (props) => {
        const find = product.filter(product => product.pro_barcode === props.keys)
        if (find.length > 0) {
            setPage(1)
        } else {
            setPage(2)
        }
    }

    return (
        <>
            <div className='container' style={{ backgroundColor: '#f5f5f5' , boxShadow:'0px 1px 2px silver',borderRadius:'10px' }}>
            <Select_form />
            {Resert()}
            </div>
        </>
    )
}

const styles = {
    form: {
        // boxShadow: '0px',
        boxShadow: '0px 1px 2px silver',
        borderRadius: '50px',
        border: '1px solid rgb(33, 150, 243)'
    },
    select: {
        display: 'flex',
        flexDirection: 'column',
        AlignItems: 'center',
        justifyContent: 'center',
        textAlignLast: 'center',
        marginTop: '30px'
    },
}

export default Desktop