import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'
import { Container, Row, Col, Card, Table, Modal, Button, Form } from 'react-bootstrap'

const Create_Account = () => {
    const [emp_name, setEmp_name] = useState('')
    const [emp_user, setEmp_user] = useState('')
    const [emp_phone, setEmp_phone] = useState('')
    const [pass, setPass] = useState('')
    const [name_status, setName_status] = useState('user')

    const [open_cash, setOpen_cash] = useState(0)
    const [add_stock, setAdd_stock] = useState(0)
    const [create_stock, setCreate_stock] = useState(0)
    const [change_stock, setChange_stock] = useState(0)
    const [tranfer, setTranfer] = useState(0)
    const [report_today, setReport_today] = useState(0)
    const [report_fit, setReport_fit] = useState(0)
    const [report_cost, setReport_cost] = useState(0)
    const [report_end_point, setReport_end_point] = useState(0)
    const [detail_store, setDetail_store] = useState(0)
    const [security, setSecurity] = useState(0)
    const [store_private, setStore_private] = useState(0)
    const [acc, setAcc] = useState(0)

    const create = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/create_account.php',
            {
                store_id: localStorage.getItem('store_id'),
                emp_name: emp_name,
                emp_user: emp_user,
                emp_phone: emp_phone,
                pass: pass,
                name_status: name_status,

                open_cash:open_cash,
                add_stock:add_stock,
                create_stock:create_stock,
                change_stock:change_stock,
                tranfer:tranfer,
                report_today:report_today,
                report_fit:report_fit,
                report_cost:report_cost,
                report_end_point:report_end_point,
                detail_store:detail_store,
                security:security,
                store_private:store_private,
                account:acc

            }).then(res => {
                if (res.data.status === 'success') {
                    alert('เพิ่มพนักงานสำเร็จ')
                }else if(res.data.status === 'cancel'){
                    alert('ไม่อนุญาติให้เพิ่มพลักงาน เนื่องจากมีชื่อผู้ใช้นี้แล้ว');
                }
            })
    }

    const List = () => {
        return (
            <>
                <tr>
                    <td>เปิดลิ้นชัก (อัตโนมัติ)</td>
                    <td align="center" valign="middle">
                        <Form.Check type="radio" name='1' checked={open_cash == 1} onChange={() => setOpen_cash(1)}></Form.Check>
                    </td>
                    <td align="center" valign="middle">
                        <Form.Check type="radio" name='1' checked={open_cash == 0} onChange={() => setOpen_cash(0)}></Form.Check>
                    </td>
                </tr>
                <tr>
                    <td>เพิ่มสินค้าเข้าคลัง</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='2' checked={add_stock == 1} onChange={() => setAdd_stock(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='2' checked={add_stock == 0} onChange={() => setAdd_stock(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>สร้างรายการสินค้า</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='3' checked={create_stock == 1} onChange={() => setCreate_stock(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='3' checked={create_stock == 0} onChange={() => setCreate_stock(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>เปลี่ยนแปลงข้อมูลสินค้า</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='4' checked={change_stock == 1} onChange={() => setChange_stock(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='4' checked={change_stock == 0} onChange={() => setChange_stock(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>โอนสินค้าระหว่างสาขา</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='5' checked={tranfer == 1} onChange={() => setTranfer(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='5' checked={tranfer == 0} onChange={() => setTranfer(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>รายงานประจำวัน</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='6' checked={report_today == 1} onChange={() => setReport_today(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='6' checked={report_today == 0} onChange={() => setReport_today(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>รายงานตามวันกำหนด</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='8' checked={report_fit == 1} onChange={() => setReport_fit(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='8' checked={report_fit == 0} onChange={() => setReport_fit(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>รายงานสินค้าถึงจุดสั่งซื้อ</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='9' checked={report_end_point == 1} onChange={() => setReport_end_point(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='9' checked={report_end_point == 0} onChange={() => setReport_end_point(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>รายงานต้นทุนทั้งหมด</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='10' checked={report_cost == 1} onChange={() => setReport_cost(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='10' checked={report_cost == 0} onChange={() => setReport_cost(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>ข้อมูลร้านค้า</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='11' checked={detail_store == 1} onChange={() => setDetail_store(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='11' checked={detail_store == 0} onChange={() => setDetail_store(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>เพิ่มผู้ใช้งานใหม่</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='12' checked={acc == 1} onChange={() => setAcc(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='12' checked={acc == 0} onChange={() => setAcc(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>สิทธิการเข้าถึง</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='13' checked={store_private == 1} onChange={() => setStore_private(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='13' checked={store_private == 0} onChange={() => setStore_private(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>ความปลอดภัย</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='14' checked={security == 1} onChange={() => setSecurity(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='14' checked={security == 0} onChange={() => setSecurity(0)}></Form.Check></td>
                </tr>


            </>
        )
    }

    return (
        <>
            <Link to={`/account/account`} style={{ textDecoration: 'none', marginLeft: '10px',color:'white' }}> <span className='bi bi-arrow-left-circle'></span> จัดการบัญชีพนักงาน</Link>
            <div className="App-header-light">

                <div className='col-lg-4 p-2 bg-white' style={{ boxShadow: '0px 1px 2px silver' }}>
                    <Table borderless>
                        <tbody>
                            <tr align="center" className='text-header'>
                                <td><span className='bi bi-plus-circle'></span> ข้อมูลพนักงาน</td>
                            </tr>

                            <tr>
                                <td>
                                    <label htmlFor="">ชื่อ - สกุล</label>
                                    <input type="text" className='form-control input_box mt-2' value={emp_name} onChange={(e) => setEmp_name(e.target.value)} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label htmlFor="">ชื่อผู้ใช้</label>
                                    <input type="text" className='form-control input_box mt-2' value={emp_user} onChange={(e) => setEmp_user(e.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="">รหัสผ่าน</label>
                                    <input type="password" className='form-control input_box mt-2' value={pass} onChange={(e) => { setPass(e.target.value) }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="">เบอร์โทรศัพท์</label>
                                    <input type="tel" className='form-control input_box mt-2' value={emp_phone} onChange={(e) => setEmp_phone(e.target.value)} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div className='col-lg-4 p-2 mt-3' style={{ boxShadow: '0px 1px 2px silver' }}>
                    <div className='text-center pt-2' style={{ color: 'rgb(23, 105, 170)' }}>
                        <span className='fas fa-user-cog'></span> กำหนดสิทธิ์การเข้าถึง
                    </div>
                    <div className='mt-3'>
                        <Table borderless>
                            <thead>
                                <tr>
                                    <th>สิทธิ์การจัดการ</th>
                                    <th>อนุญาติ</th>
                                    <th>ไม่อนุญาติ</th>
                                </tr>
                            </thead>
                            <tbody>
                                <List />
                            </tbody>
                        </Table >
                        <Button variant='primary' className='col-12 mt-2' onClick={() => create ()}> เพิ่มพนักงาน</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Create_Account