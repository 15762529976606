import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Table, Modal, Button, Alert } from 'react-bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import date from 'date-and-time';
import Clock from 'react-live-clock';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import axios from 'axios';
import Avatar from 'react-avatar';


function Main() {
    const history = useHistory()
    const days_eng = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const days_thai = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์']
    const months_eng = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const months_thai = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤษจิกายน', 'ธันวาคม']

    const [data_chart, setData_Chart] = useState([])

    function Earn_month() {
        var total = []
        const now = new Date();
        const year = date.format(now, "YYYY")
        for (let i = 1; i <= 12; i++) {
            var start = ''
            var end = ''
            if (i < 10) {
                start = '' + year + '-0' + i + '-01 00:00:00'
                end = '' + year + '-0' + i + '-31 23:59:59'
            } else {
                start = '' + year + '-' + i + '-01 00:00:00'
                end = '' + year + '-' + i + '-31 23:59:59'
            }
            const set_charts = data_chart.filter(data_chart => data_chart.date >= start && data_chart.date <= end)
            if (set_charts.length > 0) {
                var set = 0;
                set_charts.map(item => {
                    JSON.parse(item.bill_detail).map(item => {
                        set += item.earn
                    })
                })
                total.push(set)
            } else {
                total.push(0)
            }
            // console.log(total)
        }
        const data = {
            labels: months_thai,
            datasets: [
                {
                    label: 'กำไรประจำปี ' + (+date.format(now, 'YYYY') + 543),
                    data: total,
                    fill: false,
                    backgroundColor: 'rgb(23, 105, 170)',
                    borderColor: 'rgb(33, 150, 243)',
                },
            ],
        };

        const options = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            // beginAtZero: true,
                        },
                    },
                ],
            },
        };
        return (
            <Line data={data} options={options} />
        )
    }

    function Today_earn() {
        var earn = 0

        const now = new Date();
        const day = date.format(now, "YYYY-MM-DD")
        const set_charts = data_chart.filter(data_chart => data_chart.date >= day)
        if (set_charts.length > 0) {
            set_charts.map(item => {
                JSON.parse(item.bill_detail).map(item => {
                    earn += item.earn
                })
            })
        } else {
            earn += 0
        }

        return earn.toFixed(2)
    }

    function Today_earn_total() {
        var price = 0
        const now = new Date();
        const day = date.format(now, "YYYY-MM-DD")
        const set_charts = data_chart.filter(data_chart => data_chart.date >= day)
        if (set_charts.length > 0) {
            set_charts.map(item => {
                JSON.parse(item.bill_detail).map(item => {
                    price += +item.total_price
                })
            })
        } else {
            price += 0
        }

        return price.toFixed(2)
    }


    useEffect(() => {
        axios.post('http://apichatapi.ddns.net/api/pos/res_months.php', { database: localStorage.getItem('database') }).then(res => {
            if (res.data.length > 0) {
                setData_Chart(res.data)
            }
        })
        if (localStorage.getItem("store_id") === null) {
            history.push('/')
        }
    }, [])

    return (
        <div>

            <div className='container  mt-4'>
                <div className="row">
                    <div className="col-xl-8">
                        <h3 style={{ color: 'white' }}>หน้าหลัก</h3>
                    </div>
                    <div className="col-xl-4 text-end">
                        <Clock format={'DD/MM/YYYY'} ticking={true} timezone={'Asia/Bangkok'} style={{ fontSize: 18, color: 'white' }} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12 col-md-12 col-xl-3 col-xs-12 col-lg-4'>
                        <Card className='shadow border-0 mt-4'>
                            <Card.Body>
                                <Card.Title style={{ fontSize: 14, color: '#1769aa' }}> ยอดขายรายวัน</Card.Title>
                                <Card.Text style={{ fontSize: 26, textAlign: 'center' }}>
                                    <Today_earn_total />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className='shadow border-0 mt-3'>
                            <Card.Body>
                                <Card.Title style={{ fontSize: 14, color: '#1769aa' }}> ยอดขายรายวันสุทธิ</Card.Title>
                                <Card.Text style={{ fontSize: 26, textAlign: 'center' }}>
                                    <Today_earn />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className='shadow border-0 mt-3'>
                            <Card.Body>
                                <Card.Title style={{ fontSize: 14, color: '#1769aa' }}> เวลา</Card.Title>
                                <Card.Text style={{ fontSize: 26, textAlign: 'center' }}>
                                    <Clock format={'HH:mm:ss'} ticking={true} timezone={'Asia/Bangkok'} />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </div>

                    <div className='col-md-12 col-lg-8 col-xl-9 mt-4'>
                        <Card className='shadow border-0'>
                            <Card.Body>
                                <Card.Title style={{ fontSize: 14, color: '#1769aa' }}> ยอดขายรายปี</Card.Title>
                                <Card.Text style={{ fontSize: 26, textAlign: 'center' }}>
                                    {Earn_month()}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    flex: {
        display: 'flex',
    },
    flex_advance: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }
}

export default Main