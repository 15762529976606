import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Table, Modal, Form, DropdownButton, Dropdown, Offcanvas, Spinner, Tabs, Tab, Button, Navbar, Nav, NavDropdown, ListGroup } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, Prompt } from 'react-router-dom'
import axios from 'axios'
import '../../App.css'
import Select_bill from './select_bill'
import date from 'date-and-time';
import Select from 'react-select'
import CloseIcon from '@mui/icons-material/Close';


function Pos_one(props) {
    // ตรวจสอบ function ที่กำหนดแก่สมาชิกทั้งหมด
    const [open_cash, setOpen_cash] = useState(0)

    const get_function = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/get_account_feature.php', { store_id: localStorage.getItem('store_id'), emp_user: localStorage.getItem('emp_user') })
            .then(res => {
                if (res.data.length > 0) {
                    res.data.map((data) => {
                        setOpen_cash(data.open_cash)
                    })
                }
            })
    }
    // public data
    const user_id = localStorage.getItem('emp_user')
    const [product, setProduct] = useState([]) //รายการสินต้าทั้งหมด ปกติ
    const [promotion, setPromotion] = useState([]) //รายการสินต้าทั้งหมด โปรโมชั่น
    const [bill_old, setBill_old] = useState([]) // บิลย้อนหลัง
    const [cate_all, setCate_all] = useState([]) //

    // ข้อมูลเริ่มต้น 1 2 3
    const [bill_one, setBill_one] = useState([]) //รายการสินค้าในตระกร้านี้
    const [count_item_one, setCount_item_one] = useState(0) // นับจำนวนสินค้า
    const [total_item_one, setTotal_item_one] = useState(0) // ราคารวม

    // เรียกข้อมูลรายการสินค้าทั้งหมด และโปรโมชั่น
    const product_all = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/item_all.php', { database: localStorage.getItem('database') })
            .then(res => {
                if (res.data.length > 0) {
                    setProduct(res.data)
                }
            })
        axios.post('http://apichatapi.ddns.net/api/pos/item_promotion_all.php', { database: localStorage.getItem('database') })
            .then(res => {
                if (res.data.length > 0) {
                    setPromotion(res.data)
                }

            })
        axios.post('http://apichatapi.ddns.net/api/pos/cate_all.php', { database: localStorage.getItem('database') })
            .then(res => {
                if (res.data.length > 0) {
                    setCate_all(res.data)
                }

            })
    }

    // เรียกรายการบิลเริ่มต้น และหลังจากบันทึกรายการเพื่ออัพเดท 
    const fetch_bill_single = () => {
        setTimeout(() => {
            axios.post('http://apichatapi.ddns.net/api/pos/bill_all.php', { page: 1, database: localStorage.getItem('database') })
                .then(res => {
                    setBill_old(res.data)
                }).catch(err =>
                    alert(err)
                )
        }, 1500)
    }

    const [show_loadding, setShow_loadding] = useState(false);

    useEffect(() => {
        if (product.length === 0) {
            product_all()
        }
        localStorage.setItem('drop_org_1', 0)
        localStorage.setItem('print_slip_1', 0)
        localStorage.setItem('drop_org_2', 0)
        localStorage.setItem('print_slip_2', 0)
        get_function()
        fetch_bill_single()
    }, [])

    // เริ่มโปรแกรม
    function Pos_one(props) {
        const { url, path } = useRouteMatch()
        const [money, setMoney] = useState('')

        // แสดงส่วนลด
        const [show, setShow] = useState(false);
        const close_drop_price = () => setShow(false);
        const show_drop_price = () => setShow(true);

        // กำหนดแบบส่วนลด
        const config_type_drop = () => {
            if (bill_one.length === 0) {
                alert('ไม่สามารถกำหนดส่วนลดได้ เนื่องจากไม่พบรายการสินค้า !!!')
            } else {
                show_drop_price()
            }
        }

        // แสดงหน้าชำระ
        const [cash, setCash] = useState(false)
        const show_cash = () => setCash(true)
        const close_cash = () => setCash(false)

        ////////////////////////////// บิลย้อนหลัง ////////////////////////////
        // แสดงบิลย้อนหลัง
        const [page, setPage] = useState(1) // กำหนดหน้าบิล
        // const [bill_id, setBill_id] = useState('')
        const [report, setReport] = useState(false)
        const show_report = () => setReport(true)
        const close_report = () => {
            setReport(false);
            document.querySelector("#form_barcode").focus()
        }

        // เรียกรายการบิลเพิ่มเติมเมื่อ scrollbar ถึงด้านล่าง
        const fetch_bill = () => {
            setTimeout(() => {
                axios.post('http://apichatapi.ddns.net/api/pos/bill_all.php', { page: page })
                    .then(res => {
                        setBill_old(bill_old.concat(res.data))
                    }).catch(err =>
                        alert(err)
                    )
            }, 1500)
        }

        /////////////////////////////////// ส่วนงานขายสินค้า //////////////////////////////////////// 
        // ค้นหาราคาสินค้าแล้วเพิ่มบิล
        const insert_bill = (key) => {
            const original_number = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '+'];
            const text_caps = ['ฤ', '๑', '๒', '๓', '๔', 'ู', '฿', '๕', '๖', '๗', '๘', '๙'];
            const text = ['ๅ', '/', '-', 'ภ', 'ถ', 'ุ', 'ึ', 'ค', 'ต', 'จ', 'ข', 'ช']
            const tar = key.split('');

            var barcode_new = ''
            var barcode_confirm = ''
            tar.map(tar => {
                text.map((text, index) => {
                    if (tar === text) {
                        barcode_new += original_number[index]
                    } else if (tar === text_caps[index]) {
                        barcode_new += original_number[index]
                    }
                })
            })

            if (barcode_new == '') {
                barcode_confirm = key.toString()
            } else {
                barcode_confirm = barcode_new.toString()
            }

            const find_promotion = promotion.filter(promotion => promotion.pro_barcode == barcode_confirm && promotion.pro_pack == count)
            const find_product = product.find(product => product.pro_barcode === barcode_confirm)
            if (find_promotion.length !== 0) {
                Object.keys(find_promotion).forEach(key => {
                    var result = find_promotion[key]
                    var product_selected = {
                        "pro_barcode": result.pro_barcode,
                        "pro_name": result.pro_name,
                        "unit": find_product['unit'],
                        "cate": find_product['cate'],
                        "cost": find_product['cost'] * count,
                        "count": count,
                        "price": result.price_pack,
                        "total_price": result.price_pack,
                        "earn": result.price_pack - (find_product['cost'] * count)
                    }
                    setBill_one(bill_one.concat(product_selected))
                    document.querySelector('#form_barcode').value = ''
                    document.querySelector('#form_count').value = ''
                    setCount_item_one(count_item_one + parseInt(count))
                    setTotal_item_one(total_item_one + parseInt(result.price_pack))

                })
            } else {
                if (find_product !== undefined) {
                    var product_selected = {
                        "pro_barcode": find_product['pro_barcode'],
                        "pro_name": find_product['pro_name'],
                        "unit": find_product['unit'],
                        "cate": find_product['cate'],
                        "cost": find_product['cost'] * count,
                        "count": count,
                        "price": find_product['price'],
                        "total_price": find_product['price'] * count,
                        "earn": (find_product['price'] - find_product['cost']) * count
                    }
                    setBill_one(bill_one.concat(product_selected))
                    document.querySelector('#form_barcode').value = ''
                    document.querySelector('#form_count').value = ''
                    setCount_item_one(count_item_one + +count)
                    setTotal_item_one(total_item_one + parseInt(find_product['price']) * count)
                } else {
                    alert('ไม่พบรายการสินค้า')
                    document.querySelector('#form_barcode').value = ''
                    document.querySelector('#form_count').value = ''
                }

            }

        }

        // map ข้อมูลรายการสินค้า
        const Res_bill = bill_one.slice(0).reverse().map((n, index) => {
            let i = (++index - bill_one.length) * -1
            /* หลักการคือ 
                ค่า deflaut ในตัวแปล 
                    index เริ่มจาก 0
                    length เริ่มจาก 1
                1) เพิ่มจำนวน index ตั้งแต่ 0 ขึ้นไปเรื่อยๆ 
                2) นำค่าจำนวนลบกับ index เช่น
                    ++index - bill.length 
                        exp  0 - 1 = -1
                3) เมื่อได้ -1 ทำให้เป็นบวก ด้วยการ * -1
            */
            return (
                <tr id={i} >
                    <td valign='middle'>{i + 1}</td>
                    <td valign='middle'>{n.pro_name}</td>
                    <td valign='middle'>{n.price}</td>
                    <td valign='middle'>{n.count}</td>
                    <td valign='middle'>{n.total_price}</td>
                    <td width='10' valign='middle'><button style={{ border: '0px', borderRadius: '3px', background: '#ff5722', color: 'white' }} onClick={() => delete_order(i, n.total_price, n.count)}> <CloseIcon style={{}} /></button></td>
                </tr>
            )
        })

        ///////////////////////// ยกเลิกรายการนี้ ////////////////////////
        const reset_bill = () => {
            let con = window.confirm("ต้องการยกเลิกบิลนี้หรือไม่ ?")
            if (con == true) {
                setBill_one([])
                document.querySelector('#form_barcode').focus()
                setCount_item_one(0)
                setTotal_item_one(0)
            } else {
                document.querySelector('#form_barcode').focus()
            }
        }
        // ลบรายการ
        const delete_order = (key, total, drop_count) => {
            bill_one.splice(key, 1)
            setTotal_item_one(total_item_one - total)
            setCount_item_one(count_item_one - drop_count)
        }

        ///////////////////////// ชำระค่าสินค้า ////////////////////////
        const success_bill = () => {
            axios.post('http://apichatapi.ddns.net/api/pos/success_bill.php',
                {
                    bill_detail: bill_one,
                    total_price: total_item_one,
                    discount: drop_one,
                    net_price: total_item_one - drop_one,
                    payment: money,
                    date: date.format(new Date(), 'YYYY-MM-DD H:mm:s'),
                    user: user_id,
                    database: localStorage.getItem('database')
                }).then(res => {
                    if (res.data.bill_detail === 'success' && res.data.bill_id !== "") {
                        if (open_cash == 1) {
                            setBill_one([]) //รายการสินค้าในตระกร้านี้
                            setCount_item_one(0) // นับจำนวนสินค้า
                            setTotal_item_one(0) // ราคารวม
                            setDrop_one(0) // ส่วนลดประมวลผลแล้ว
                            setDrop_org('')
                            setPage(1)
                            fetch_bill_single()
                            setMoney('')
                            localStorage.setItem('print_slip_1', 0)
                            localStorage.setItem('drop_org_1', 0)
                            update_last_money(money - (total_item_one - drop_one))
                            if (print_slip == 1) {
                                var WindowObject = window.open("http://apichatapi.ddns.net/api/pos/print_slip.php?bill_id=" + res.data.bill_id + "&store_id=" + localStorage.getItem("store_id") + "&database=" + localStorage.getItem('database') + "&state=0", "_blank", "width=1000,height=800")
                                // WindowObject.print()
                                // WindowObject.close()
                                close_cash()
                                setCount(1)

                            } else {
                                var WindowObject = window.open('http://apichatapi.ddns.net/api/pos/open_cash.php?print_bill=0', "", "width=1000,height=800,top=10,left=10,toolbars=no,scrollbars=no,status=no,resizable=no");
                                WindowObject.print()
                                WindowObject.close()
                                close_cash()
                                setCount(1)
                            }

                        }

                    } else {
                        alert('เกิดข้อผิดพลาด')
                    }
                }).catch(err => {
                    alert(err)
                })
        }

        const [drop_one, setDrop_one] = useState(localStorage.getItem('drop_org_1')) // ส่วนลดประมวลผลแล้ว
        const [type_drop, setType_drop] = useState('บาท') // ชนิดของส่วนลด : บาท , เปอร์เซ็น
        const [drop_org, setDrop_org] = useState('') // ส่วนลดดั้งเดิม
        const [print_slip, setPrint_slip] = useState(localStorage.getItem('print_slip_1'))
        const [last_money, setLast_money] = useState(localStorage.getItem('last_money_1'))
        const [count, setCount] = useState(1)

        const print_updates = () => {
            if (print_slip == 1) {
                setPrint_slip(0)
                localStorage.setItem('print_slip_1', 0)
            } else {
                setPrint_slip(1)
                localStorage.setItem('print_slip_1', 1)
            }
        }

        const drop_updates = (total) => {
            if (total !== '') {
                setDrop_one(total)
                localStorage.setItem('drop_org_1', total)
            } else if (total === '') {
                setDrop_one('')
                localStorage.setItem('drop_org_1', 0)
            }
        }

        const update_last_money = (total) => {
            if (total !== '') {
                setLast_money(total)
                localStorage.setItem('last_money_1', total)
                setTimeout(() => {
                    setLast_money(0)
                    localStorage.setItem('last_money_1', 0)
                }, 5000)

            } else if (total === '') {
                setLast_money('')
                localStorage.setItem('last_money_1', 0)

                setTimeout(() => {
                    setLast_money(0)
                    localStorage.setItem('last_money_1', 0)
                }, 5000)
            }
        }

        const List_menu = () => {
            return (
                cate_all.map((res, index) => {
                    const fil = product.filter(fill => fill.cate == res.cate_name && fill.mark == 1)
                    return (
                        <>
                            <ListGroup variant="flush">
                                {
                                    fil.length !== 0
                                        ?
                                        <>
                                            <ListGroup.Item disabled><small style={{ color: '#7A7A7A', marginTop: '10px' }}><b>{res.cate_name}</b><br /></small></ListGroup.Item>

                                            {
                                                fil.map((item, index) => {
                                                    if (item.mark == 1) {
                                                        return (
                                                            <>
                                                                <ListGroup.Item action onClick={() => {
                                                                    let count = document.querySelector('#form_count').value
                                                                    if (count <= 1) {
                                                                        count = 1
                                                                        insert_bill(item.pro_barcode, count);
                                                                    } else {
                                                                        insert_bill(item.pro_barcode, count);
                                                                    }
                                                                }}
                                                                >
                                                                    {item.pro_name} <span style={{float:'right'}}>{item.price} .-</span>
                                                                </ListGroup.Item>

                                                            </>
                                                        )
                                                    }
                                                })
                                            }
                                        </>
                                        : <></>
                                }
                            </ListGroup>
                        </>
                    )
                })

            )
        }

        return (
            <>
                <Modal show={show_loadding} onHide={() => setShow_loadding(false)}>
                    <Modal.Body><Spinner animation="border" variant="primary" /> กำลังโหลดข้่อมูล <br />กรุณารอสักครู่</Modal.Body>
                </Modal>
                {/* ขายสินค้า */}
                    <div className="d-flex">
                        <div className="col-lg-9 m-1">
                            <div>
                                <table border='0'>
                                    <tbody>
                                        <tr>
                                            {/* แสดงยอดรวม */}
                                            <td align='center' valign='middle' style={{ width: '30%' }} rowspan="2">
                                                <div className="" style={{ fontSize: '14px' }}>
                                                    <Form.Text>ส่วนลด {drop_one === '' ? <>0</> : drop_one} บาท</Form.Text>
                                                </div>
                                                <div style={{ fontSize: '45px', color: '#1769aa' }}>
                                                    <b>{total_item_one - drop_one}</b>
                                                </div>
                                                <small>ทอนล่าสุด</small> <span style={{ fontSize: '20px', color: '#4caf50' }}><b>{last_money} .-</b></span>
                                            </td>
                                            {/* รับรหัสสินค้า */}
                                            <td align='left' valign='middle' style={{ width: '40%' }} >
                                                <input type="text" className='form-control' list='product_all' placeholder='บาร์โค้ด / ชื่อสินค้า' id='form_barcode' autoFocus
                                                    onKeyPress={(e) => {
                                                        if (e.key == 'Enter') {
                                                            if (e.target.value !== "") {
                                                                insert_bill(
                                                                    e.target.value, //รหัสสินค้า
                                                                )
                                                            } else {
                                                                if (bill_one.length !== 0) {
                                                                    show_cash()
                                                                } else {
                                                                    alert('กรุณาเพิ่มสินค้าก่อนชำระ')
                                                                }
                                                            }
                                                        }
                                                        else if (e.key == '+' || e.key == '-' || e.key == 'F1' || e.key == 'F2' || e.key == 'F3' || e.key == 'F4') {
                                                            e.preventDefault()
                                                        }

                                                        if (e.key == '+') {
                                                            setCount(count + 1)
                                                        } else if (e.key == '-') {
                                                            count <= 1
                                                                ? setCount(1)
                                                                : setCount(count - 1)
                                                        }
                                                    }
                                                    }

                                                    onKeyUp={(e) => {
                                                        let count = 0
                                                        if (e.key == "F1") {
                                                            e.preventDefault()
                                                            reset_bill()
                                                        } else if (e.key == "F4") {
                                                            e.preventDefault()
                                                            show_drop_price()
                                                        } else if (e.key == "F3") {
                                                            e.preventDefault()
                                                            show_report()
                                                        }
                                                        else if (e.key == 'F9') {
                                                            e.preventDefault()
                                                            alert('กำลังเปิดลิ้นชัก')
                                                            var WindowObject = window.open("http://apichatapi.ddns.net/api/pos/open_cash.php?print_bill=0", "_blank", "width=1000,height=800")
                                                            WindowObject.print()
                                                            WindowObject.close()
                                                        }
                                                    }
                                                    }
                                                />
                                                <datalist id='product_all'>
                                                    {
                                                        product.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.pro_barcode}>{item.pro_barcode} :::: {item.pro_name}</option>
                                                            )
                                                        })
                                                    }
                                                </datalist>
                                            </td>
                                            {/* จำนวนสินค้า */}
                                            <td align='center' valign='middle'>

                                                <input type="number" className='form-control text-center' value={count} placeholder='จำนวน' id='form_count'
                                                    onChange={(e) => {
                                                        if (e.target.value < 0) {
                                                            setCount(1)
                                                        } else {
                                                            setCount(e.target.value)
                                                        }
                                                    }}
                                                    onKeyUp={(e) => {
                                                        if (e.keyCode == 13) {
                                                            document.querySelector('#form_barcode').focus()
                                                        }
                                                    }}
                                                />
                                            </td>

                                            <td valign='middle' style={{ width: '20%' }}>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td align='left' valign='middle' colspan='2'>
                                                <button className='btn btn btn-sm btn-light m-1 border' onClick={reset_bill}> F1 ยกเลิกบิล</button>
                                                <button className='btn btn btn-sm btn-light m-1 border' onClick={show_report}>F3 บิลย้อนหลัง</button>
                                                <button className='btn btn btn-sm btn-light m-1 border' onClick={config_type_drop}>F4 เพิ่มส่วนลด</button>
                                                <button className='btn btn btn-sm btn-light border m-1' onClick={() => {
                                                    if (bill_one.length !== 0) {
                                                        show_cash()
                                                    } else {
                                                        alert('กรุณาเพิ่มสินค้าก่อนชำระ')
                                                        document.querySelector('#form_barcode').focus()
                                                    }
                                                }}>บันทึกรายการ</button>&nbsp;
                                            </td>
                                            <td>
                                                {
                                                    open_cash == 1
                                                        ?
                                                        <div className="">
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="custom-switch"
                                                                label="พิมพ์ใบเสร็จ"
                                                                style={{ marginTop: '5px' }}
                                                                checked={print_slip == 1}
                                                                onChange={(e) => {
                                                                    print_updates();
                                                                    document.querySelector('#form_barcode').focus()
                                                                }}
                                                            />
                                                        </div>
                                                        : <></>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan='4'>
                                                {/* <Col lg={12} className='p-2 pt-0'> */}
                                                <Table responsive="sm" style={styles.table_list} bordered={true} hover className='table-sm'>
                                                    <thead className='bg-light active'>
                                                        <tr>
                                                            <th>#</th>
                                                            <th className='text-center'>รายการสินค้า</th>
                                                            <th>ราคา/ชิ้น</th>
                                                            <th>จำนวน</th>
                                                            <th>ราคารวม</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            Res_bill
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan='3'><b>สรุปรายการสินค้า</b></td>
                                                            <td><b>{count_item_one}</b></td>
                                                            <td><b>{total_item_one}</b></td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                                {/* </Col> */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-3 p-2" style={{height:'100vh',overflow:'scroll'}}>
                            <List_menu />
                        </div>
                    </div>

                {/* ฟอร์มส่วนลด */}
                <Modal
                    show={show}
                    autoFocus={true}
                    animation={false}
                    keyboard={false}
                >

                    <Modal.Body className='pt-1 pb-1 bg-success' style={{ borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
                        <b className='text-white'>กำหนดส่วนลด</b>
                        <button className='btn btn-success active float-end pt-0 pb-0' style={{ cursor: 'pointer' }} onClick={() => { close_drop_price(); document.querySelector('#form_barcode').focus() }}> <CloseIcon /></button>

                    </Modal.Body>
                    <Modal.Body className='bg-white'>
                        <Row>
                            <Col xl={6}>
                                ชนิดส่วนลด
                                <div className='mt-2'>
                                    <Form.Check
                                        type="radio"
                                        label="บาท"
                                        name="drop"
                                        checked={type_drop === 'บาท'}
                                        onChange={() => setType_drop('บาท')}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="เปอร์เซ็น"
                                        name="drop"
                                        checked={type_drop === 'เปอร์เซ็น'}
                                        onChange={() => setType_drop('เปอร์เซ็น')}
                                    />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <Form.Label>ส่วนลด / {type_drop}</Form.Label>
                                <Form.Control type='number' id='form_drop' autoFocus value={drop_org} placeholder={'กรอกส่วนลด (' + type_drop + ')'}
                                    onChange={(e) => {
                                        setDrop_org(e.target.value)
                                        var simple_get_drop = e.target.value
                                        if (type_drop === 'บาท') {
                                            drop_updates(simple_get_drop)
                                        } else if (type_drop === 'เปอร์เซ็น') {
                                            var potal = total_item_one * simple_get_drop / 100
                                            drop_updates(potal)
                                        }
                                    }
                                    }
                                    onKeyUp={(e) => {
                                        if (e.keyCode == 13) {
                                            close_drop_price()
                                            document.querySelector('#form_barcode').focus()
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <ul>
                            <li style={{ listStyle: 'none' }}>ราคาสินค้า <span className='float-end'> {total_item_one}</span></li>
                            <li style={{ listStyle: 'none' }}>ส่วนลด <span className='float-end'> {drop_one}</span></li>
                            <li style={{ listStyle: 'none' }}>ราคาสุทธิ <span className='float-end'> {total_item_one - drop_one}</span></li>
                        </ul>
                    </Modal.Body>
                </Modal>

                {/* แสดงบิลย้อนหลัง */}
                <Modal
                    show={report}
                    animation={false}
                    autoFocus={true}
                    keyboard={false}
                    size='xl'
                    className='m-0 p-0'
                >
                    <BrowserRouter basename='/demo'>
                        <Modal.Header className='pt-1 pb-1 shadow-sm bg-light' style={{ background: '#4b565A' }}>
                            <b style={{ marginTop: '0px' }}>บิลย้อนหลัง</b>
                            <Link to={`${path}`} className='btn btn float-end pt-0 pb-0' style={{ cursor: 'pointer' }} onClick={() => { close_report(); document.querySelector('#form_barcode').focus() }}> <CloseIcon /> </Link>
                        </Modal.Header>

                        <Modal.Body className='pt-1 pb-0 '>
                            <Row>
                                <Col xl={4} className='p-0' style={styles.left} onScroll={(e) => {
                                    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
                                        fetch_bill();
                                        setPage(page + 1)
                                    }
                                }}>
                                    <Table hover borderless size="sm" responsive="xl">
                                        <thead className='table-light' bordered>
                                            <tr>
                                                <td className='text-center'>รหัสรายการ</td>
                                                <td className='text-center'>วันที่</td>
                                                <td className='text-center'>เวลา</td>
                                                <td className='text-center'>เรียกดู</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bill_old.map((n, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='text-center'>{n.bill_id}</td>
                                                        <td className='text-center'>{date.format((new Date(n.date)), 'HH:mm')}</td>
                                                        <td className='text-center'>{date.format((new Date(n.date)), 'DD/MM/YYYY')}</td>
                                                        <td className='text-center p-0'><Link to={`${path}/` + n.bill_id} style={{ textDecoration: 'none', fontSize: '20px' }} className='bi bi-check-circle text-success' ></Link></td>
                                                    </tr>

                                                )

                                            })}
                                        </tbody>
                                    </Table>

                                </Col>
                                <Col xl={8} className='bg-light' style={{ padding: '0px' }}>
                                    <Switch>
                                        <Route exact path={`${path}`}>

                                        </Route>
                                        <Route path={`${path}/:bill_id`}>
                                            <div style={styles.bill_detail}>
                                                <Select_bill />
                                            </div>
                                        </Route>
                                    </Switch>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </BrowserRouter>
                </Modal>

                {/* ชำระค่าสินค้า */}
                <Modal
                    show={cash}
                    animation={false}
                    autoFocus={true}
                    keyboard={false}
                    size='lg'
                    className='m-0 p-0'
                >
                    <Modal.Header className='pt-1 pb-1 shadow-sm bg-light'>
                        <b style={{ marginTop: '1px' }}>ชำระค่าสินค้า</b>
                        <Link to={`${path}`} className='btn btn float-end pt-0 pb-0' style={{ cursor: 'pointer' }} onClick={() => { close_cash(); document.querySelector('#form_barcode').focus() }}> <CloseIcon /> </Link>
                    </Modal.Header>

                    <Modal.Body className='pt-2 '>
                        <Row>
                            <Col xl={5} className='' style={styles.left_cash}>
                                <center colSpan='2'>รายการสินค้า</center>
                                <hr className='mt-1 mb-1' />
                                {bill_one.map((n, index) => {
                                    return (
                                        <li key={index} style={{ listStyle: 'none', paddingLeft: '8px', paddingRight: '8px' }}>{n.pro_name} x {n.count} <span className='float-end'>{n.total_price}.-</span></li>
                                    )
                                })}
                                <div className="card card-body border-0 p-2" style={{ fontSize: '16px', color: '#0009' }}>
                                    <li style={{ listStyle: 'none' }}>ราคารวมสินค้า <span className='float-end'>{total_item_one}.-</span></li>
                                    <li style={{ listStyle: 'none' }}>ส่วนลด <span className='float-end'>{drop_one}.-</span></li>
                                    <li style={{ listStyle: 'none' }}>ราคาสุทธิ <span className='float-end'>{total_item_one - drop_one}.-</span></li>
                                </div>
                            </Col>

                            <Col xl={7} className=''>
                                <div className="card bg-light border-0" style={{ boxShadow: '0px 1px 2px silver' }}>
                                    <div className="card-body">
                                        <Row>
                                            <Col lg={5}>
                                                <div className='mt-3'>ราคาสุทธิ</div>
                                            </Col>
                                            <Col lg={7}>
                                                <input type="text" disabled className='form-control shadow-sm text-center bg-white' value={total_item_one - drop_one} name="" id="" style={{ fontSize: '30px' }} />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="card bg-light border-0 mt-2" style={{ boxShadow: '0px 1px 2px silver' }}>
                                    <div className="card-body">
                                        <Row>
                                            <Col lg={5}>
                                                <div className='mt-3'>รับเงิน</div>
                                            </Col>
                                            <Col lg={7}>
                                                <input type="text" className='form-control shadow-sm text-center' value={money} autoFocus name=""
                                                    onChange={(e) => setMoney(e.target.value)}
                                                    onKeyUp={(e) => {
                                                        if (e.keyCode == 13) {
                                                            if (e.target.value === '') {
                                                                // alert('กรุณารับเงิน')
                                                            } else if (e.target.value >= total_item_one - drop_one) {
                                                                success_bill()
                                                                setLast_money(money - (total_item_one - drop_one))

                                                            } else if (e.target.value < total_item_one - drop_one) {
                                                                alert('กรุณารับเงินให้ถูกต้อง ก่อนบันทึกรายการ')
                                                            }
                                                        } else if (e.keyCode == 27) {
                                                            close_cash()
                                                            document.querySelector('#form_barcode').focus()
                                                        }
                                                    }}

                                                    style={{ fontSize: '30px' }} />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="card border-0 mt-2" style={{ boxShadow: '0px 1px 2px silver', background: '#ff9800' }}>
                                    <div className="card-body">
                                        <Row>
                                            <Col lg={5}>
                                                <div className='mt-3 text-white'><h5>ทอน</h5></div>
                                            </Col>
                                            <Col lg={7}>
                                                <input type="text" disabled className='form-control shadow-sm text-center bg-white' value={money - (total_item_one - drop_one)} name="" id="" style={{ fontSize: '30px' }} />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        )
    }

    return <Pos_one />
}


const styles = {
    table_list: {
        textAlign: 'center',
        marginTop: '20px',
        border: '#c8e4fb'
    },
    count: {
        boxShadow: '0px 1px 2px silver',
    },
    left: {
        height: '80vh',
        overflow: 'scroll',
    },
    left_cash: {
        height: 'auto',
        overflow: 'scroll',
    },
    bill_detail: {
        overflow: 'scroll',
        height: '80vh',
    },

}

export default Pos_one;
