import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Table, Modal, Button, Alert, Tab } from 'react-bootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import date from 'date-and-time';
import Clock from 'react-live-clock';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { Line } from 'react-chartjs-2';
import axios from 'axios';

const Report_today = () => {
    const [data, setData] = useState([])
    const months_eng = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const months_thai = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤษจิกายน', 'ธันวาคม']

    var discount = 0
    var total_price = 0
    var net_price = 0
    var earn = 0

    const [date_today_earn, setDate_today_earn] = useState(0)
    const [date_today, setDate_today] = useState('')
    const now = new Date();
    const fetch = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/report_today.php', { database: localStorage.getItem('database'), start: date.format(now, 'YYYY-MM-DD 00:00:00'), end: date.format(now, 'YYYY-MM-DD 23:59:59') }).then(res => {
            if (res.data.length > 0) {
                setData(res.data)
            }
        })
    }

    function print_slip(bill_id) {
        window.open("http://apichatapi.ddns.net/api/pos/print_slip.php?bill_id=" + bill_id + "&store_id=" + localStorage.getItem("store_id") + "&database=" + localStorage.getItem('database'), "_blank", "width=300,height=1000")
    }

    function print_a4(bill_id, name, address) {
        window.open("http://apichatapi.ddns.net/api/pos/print_a4.php?bill_id=" + bill_id + "&store_id=" + localStorage.getItem("store_id") + "&database=" + localStorage.getItem('database'), "_blank", "width=1024,height=768")
    }

    function response_date() {
        const day = date.format(now, 'DD');
        const M = date.format(now, 'MMMM');
        const year = +date.format(now, 'YYYY') + 543;

        let date_set_res = ''
        for (let i = 0; i < months_eng.length; i++) {
            if (M === months_eng[i]) {
                date_set_res = months_thai[i]
            }
        }
        setDate_today(day + ' - ' + date_set_res + ' - ' + year)
    }

    const Earn_fires = () => {
        var earn_fires = 0
        data.reverse().map((item, index) => {
            JSON.parse(item.bill_detail).map(ser => {
                earn_fires += +ser.earn
            })
        })
        return <>{earn_fires.toFixed(2)}</>
    }

    useEffect(() => {
        fetch()
        response_date()
    }, [])

    return (
        <>
            <div className="container" style={styles.flex_advance}>
                <center>
                <div className='text-center text-white mt-3' style={{ fontSize: '18px' }}>
                    รายงานการขายประจำวันที่ <br /><small>{date_today}</small>
                </div>
                <div className='text-center text-white mt-3' style={{ fontSize: '20px'}}>
                    **** กำไรสุทธิ <Earn_fires/>.- ****
                </div>
                <div className='text-center col-lg-12 col-xl-11 mt-3 bg-white'>
                    <Table responsive="sm" style={{ boxShadow: '0px 1px 2px silver' }} hover borderless>
                        <thead className=' text-header' style={{ boxShadow: '0px 1px 2px silver', borderTop: '5px solid rgb(23, 105, 170)' }}>
                            <tr>
                                <th>เลขที่บิล</th>
                                <th>เวลา</th>
                                <th>ราคาสินค้า</th>
                                <th>ส่วนลด</th>
                                <th>ราคาสุทธิ</th>
                                <th>กำไร</th>
                                <th>รายละเอียดเพิ่มเติม</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.slice(0).reverse().map((item, index) => {
                                    var total = 0;

                                    discount += +item.discount
                                    total_price += +item.total_price
                                    net_price += +item.net_price

                                    JSON.parse(item.bill_detail).map(ser => {
                                        total += +ser.earn
                                        earn += +ser.earn
                                    })
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{item.bill_id}</td>
                                                <td>{date.format(new Date(item.date), 'H:m:ss')}</td>
                                                <td>{item.total_price}</td>
                                                <td>{item.discount}</td>
                                                <td>{item.net_price}</td>
                                                <td>{total.toFixed(2)}</td>
                                                <td className='col-xl-2'>
                                                    <span onClick={() => print_slip(item.bill_id)} style={{ cursor: 'pointer', color: '#1769aa' }}>Slip <span className='fas fa-chevron-right'></span></span> &nbsp; &nbsp; &nbsp;
                                                    <span onClick={() => print_a4(item.bill_id)} style={{ cursor: 'pointer', color: '#357a38' }}>Receipt <span className='fas fa-chevron-right'></span></span>
                                                </td>
                                            </tr>
                                        </>
                                    )

                                })

                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan='2'>สรุปรายงาน</th>
                                <th>{total_price}</th>
                                <th>{discount}</th>
                                <th>{net_price.toFixed(2)}</th>
                                <th>{earn.toFixed(2)}</th>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
                </center>
            </div>
        </>
    )
}

const styles = {
    flex_advance: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        height: '100vh',
        overflow:'scroll'
        // backgroundColor:'white',
        // margin:'20px 100px 0px 100px',
        // borderRadius:'10px',
        // boxShadow:'0px 1px 2px silver'
    }
}

export default Report_today