import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Table, Modal, Button, Form } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'
import md5 from 'md5'

function Form_features() {
    const { account } = useParams()
    const [open_cash, setOpen_cash] = useState()
    const [add_stock, setAdd_stock] = useState()
    const [create_stock, setCreate_stock] = useState()
    const [change_stock, setChange_stock] = useState()
    const [tranfer, setTranfer] = useState()
    const [report_today, setReport_today] = useState()
    const [report_fit, setReport_fit] = useState()
    const [report_cost, setReport_cost] = useState()
    const [report_end_point, setReport_end_point] = useState()
    const [detail_store, setDetail_store] = useState()
    const [security, setSecurity] = useState()
    const [store_private, setStore_private] = useState()
    const [acc, setAcc] = useState()

    const get = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/get_account_feature.php', { store_id: localStorage.getItem('store_id'), emp_user: account })
            .then(res => {
                if (res.data.length > 0) {
                    res.data.map((data) => {
                        setOpen_cash(data.open_cash)
                        setAdd_stock(data.add_to_stock)
                        setCreate_stock(data.create_list_item)
                        setChange_stock(data.chang_list_product)
                        setTranfer(data.tranfer_product)
                        setReport_today(data.report_today)
                        setReport_fit(data.report_fit)
                        setReport_end_point(data.report_end_point)
                        setReport_cost(data.report_cost)
                        setDetail_store(data.data_store)
                        setStore_private(data.private)
                        setSecurity(data.security)
                        setAcc(data.account)
                    })
                }
            })
    }

    const update = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/update_feature.php', {
            store_id: localStorage.getItem('store_id'),
            emp_user: account,
            open_cash: open_cash,
            add_stock: add_stock,
            create_stock: create_stock,
            change_stock: change_stock,
            tranfer: tranfer,
            report_today: report_today,
            report_fit: report_fit,
            report_cost: report_cost,
            report_end_point: report_end_point,
            detail_store: detail_store,
            security: security,
            store_private: store_private,
            account: acc
        }).then(res => {
            if (res.data.status === 200) {
                alert('บันทึกการเปลี่ยนแปลงสำเร็จ')
                get()
            }
        }).catch(err => {
            alert(err)
        })
    }

    const List = () => {
        return (
            <>
                <tr>
                    <td>เปิดลิ้นชัก (อัตโนมัติ)</td>
                    <td align="center" valign="middle">
                        <Form.Check type="radio" name='1' checked={open_cash == 1} onChange={() => setOpen_cash(1)}></Form.Check>
                    </td>
                    <td align="center" valign="middle">
                        <Form.Check type="radio" name='1' checked={open_cash == 0} onChange={() => setOpen_cash(0)}></Form.Check>
                    </td>
                </tr>
                <tr>
                    <td>เพิ่มสินค้าเข้าคลัง</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='2' checked={add_stock == 1} onChange={() => setAdd_stock(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='2' checked={add_stock == 0} onChange={() => setAdd_stock(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>สร้างรายการสินค้า</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='3' checked={create_stock == 1} onChange={() => setCreate_stock(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='3' checked={create_stock == 0} onChange={() => setCreate_stock(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>เปลี่ยนแปลงข้อมูลสินค้า</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='4' checked={change_stock == 1} onChange={() => setChange_stock(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='4' checked={change_stock == 0} onChange={() => setChange_stock(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>โอนสินค้าระหว่างสาขา</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='5' checked={tranfer == 1} onChange={() => setTranfer(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='5' checked={tranfer == 0} onChange={() => setTranfer(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>รายงานประจำวัน</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='6' checked={report_today == 1} onChange={() => setReport_today(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='6' checked={report_today == 0} onChange={() => setReport_today(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>รายงานตามวันกำหนด</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='8' checked={report_fit == 1} onChange={() => setReport_fit(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='8' checked={report_fit == 0} onChange={() => setReport_fit(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>รายงานสินค้าถึงจุดสั่งซื้อ</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='9' checked={report_end_point == 1} onChange={() => setReport_end_point(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='9' checked={report_end_point == 0} onChange={() => setReport_end_point(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>รายงานต้นทุนทั้งหมด</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='10' checked={report_cost == 1} onChange={() => setReport_cost(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='10' checked={report_cost == 0} onChange={() => setReport_cost(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>ข้อมูลร้านค้า</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='11' checked={detail_store == 1} onChange={() => setDetail_store(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='11' checked={detail_store == 0} onChange={() => setDetail_store(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>เพิ่มผู้ใช้งานใหม่</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='12' checked={acc == 1} onChange={() => setAcc(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='12' checked={acc == 0} onChange={() => setAcc(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>สิทธิการเข้าถึง</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='13' checked={store_private == 1} onChange={() => setStore_private(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='13' checked={store_private == 0} onChange={() => setStore_private(0)}></Form.Check></td>
                </tr>
                <tr>
                    <td>ความปลอดภัย</td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='14' checked={security == 1} onChange={() => setSecurity(1)}></Form.Check></td>
                    <td align="center" valign="middle"><Form.Check type="radio" name='14' checked={security == 0} onChange={() => setSecurity(0)}></Form.Check></td>
                </tr>


            </>
        )
    }

    useEffect(() => {
        get()
    }, [account])

    return (
        <>
            <Link to={`/account/private`} style={{ textDecoration: 'none', marginLeft: '10px', color: 'white' }}> <span className='bi bi-arrow-left-circle'></span> เลือกบัญชีที่ต้องการกำหนดสิทธิ์การเข้าถึง</Link>
            <div className="App-header-light">
                <div className='col-xl-4 p-2'>
                    <Modal.Dialog>
                        <Modal.Header>
                            <Modal.Title stlye={{ fontSize: '20px' }}>กำหนดสิทธิ์การเข้าถึง</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className='text-center h5 pt-2' style={{ color: 'rgb(23, 105, 170)' }}>
                                <span className='fas fa-user-cog'></span>
                            </div>
                            <div className='mt-3'>
                                <Table borderless>
                                    <thead>
                                        <tr>
                                            <th>สิทธิ์การจัดการ</th>
                                            <th>อนุญาติ</th>
                                            <th>ไม่อนุญาติ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <List />
                                    </tbody>
                                    <tfoot className=''>
                                        <tr>
                                            <td colspan='3'><Button variant='primary' className='col-12 mt-2' onClick={() => update()}> บันทึกการเปลี่ยนแปลง</Button></td>
                                        </tr>
                                    </tfoot>
                                </Table >

                            </div>
                        </Modal.Body>

                    </Modal.Dialog>
                </div>
            </div>
        </>
    )
}


export default Form_features