import React, { useState, useEffect } from 'react'
import render from 'react-dom'
import { Container, Row, Col, Card, Table, Modal, Button, Form } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, Redirect, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'
import '../../App.css'
import Create from './create'

const Desktop = () => {
    const history = useHistory()
    const { url, path } = useRouteMatch()
    const [product, setProduct] = useState([])
    const [key, setKey] = useState('')
    const [page, setPage] = useState(0)

    const [barcode_orginal, setBarcode_orginal] = useState('')
    const [barcode, setBarcode] = useState('')
    const [pro_name, setPro_name] = useState('')
    const [cate, setCate] = useState('')
    const [cost, setCost] = useState('')
    const [price, setPrice] = useState('')
    const [stock, setStock] = useState('')
    const [end_point, setEnd_point] = useState('')
    const [mark, setMark] = useState('')

    const search = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/item_all.php', { database: localStorage.getItem('database') }).then(res => {
            setProduct(res.data)
        })
    }
    useEffect(() => {
        search()
    }, [])

    const Select_form = () => {
        return (
            <div>
                <div className="d-flex justify-content-center mt-3 pt-4 ">
                    <Col xs={12} xl={4}>
                        <input type="text" class="form-control text-center" list="seach" placeholder="กรอกบาร์โค้ด / ชื่อสินค้าที่ต้องการ" style={styles.form}
                            onKeyUp={(e) => {
                                if (e.keyCode === 13) {
                                    setKey(e.target.value)
                                    Feed({ keys: e.target.value })
                                }
                            }}
                        />

                    </Col>
                </div>
                <datalist id='seach' style={styles.datalist}>
                    {
                        product.map((product, index) => {
                            return (
                                <option key={index} value={product.pro_barcode}>{product.pro_barcode} ::: {product.pro_name}</option>
                            )
                        })
                    }
                </datalist>
            </div >
        )
    }

    const update = () => {
        axios.post('http://apichatapi.ddns.net/api/pos/update_data_stock.php',
            {
                barcode_orginal: barcode_orginal,
                barcode: barcode,
                pro_name: pro_name,
                cate: cate,
                cost: cost,
                price: price,
                stock: stock,
                end_point: end_point,
                mark: mark,
                database: localStorage.getItem('database')
            }).then(res => {
                if (res.data.status === 200) {
                    search()
                    alert('อัพเดทข้อมูลสินค้าสำเร็จ')
                }
            }).catch((err) => {
                alert(err)
            })
    }
    const del = () => {
        var i = window.confirm('กรุณายืนยัน เพื่อลบรายการ')
        if (i === true) {
            axios.post('http://apichatapi.ddns.net/api/pos/delete_stock.php', { barcode: barcode_orginal, database: localStorage.getItem('database') }).then(res => {
                if (res.data.status === 200) {
                    search()
                    alert('ลบข้อมูลสินค้าสำเร็จ')
                    setPage(0)
                }
            })

        }
    }

    function edit() {
        return (
            <>
                <div className='d-flex justify-content-center'>
                    <div className='col-xl-5 col-12 mt-4 p-2' >
                        <Table borderless size='sm' style={{ marginTop: '20px' }}>
                            <tbody style={{ verticalAlign: 'middle' }}>
                                <tr >
                                    <td>รหัสสินค้า</td>
                                    <td><input type="text" className='form-control input_line' value={barcode} onChange={(e) => setBarcode(e.target.value)} style={{ fontSize: '18px', textAlign: 'center' }} /></td>
                                </tr>
                                <tr>
                                    <td>ชื่อสินค้า</td>
                                    <td><input type="text" className='form-control input_line' value={pro_name} onChange={(e) => setPro_name(e.target.value)} style={{ fontSize: '18px', textAlign: 'center' }} /></td>
                                </tr>
                                <tr>
                                    <td>ประเภท</td>
                                    <td><input type="text" className='form-control input_line' value={cate} onChange={(e) => setCate(e.target.value)} style={{ fontSize: '18px', textAlign: 'center' }} /></td>
                                </tr>
                                <tr>
                                    <td>ต้นทุนสินค้า</td>
                                    <td><input type="text" className='form-control input_line' value={cost} onChange={(e) => setCost(e.target.value)} style={{ fontSize: '18px', textAlign: 'center' }} /></td>
                                </tr>
                                <tr>
                                    <td>ราคาสินค้า</td>
                                    <td><input type="text" className='form-control input_line' value={price} onChange={(e) => setPrice(e.target.value)} style={{ fontSize: '18px', textAlign: 'center' }} /></td>
                                </tr>
                                <tr>
                                    <td>จำนวนคงเหลือ</td>
                                    <td><input type="text" className='form-control input_line' value={stock} onChange={e => setStock(e.target.value)} style={{ fontSize: '18px', textAlign: 'center' }} /></td>
                                </tr>
                                <tr>
                                    <td>จุดสั่งซื้อ</td>
                                    <td><input type="text" className='form-control input_line' value={end_point} onChange={e => setEnd_point(e.target.value)} style={{ fontSize: '18px', textAlign: 'center' }} /></td>
                                </tr>
                                <tr>
                                    <td>สินค้านิยม</td>
                                    <td>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="เป็นสินค้านิยม"
                                            style={{ marginTop: '10px', marginLeft: '20px' }}
                                            checked={mark == 1}
                                            onChange={(e) => {
                                                if (mark == 1) {
                                                    setMark(0)
                                                } else {
                                                    setMark(1)
                                                }
                                            }
                                            }
                                        />
                                    </td>
                                </tr>
                                <br />
                            </tbody>
                        </Table>
                        <center><Button className='col-12 border-0 col-xl-6' style={{ backgroundColor: '#2196f3', boxShadow: '0px 1px 2px silver' }} onClick={() => { update() }}>เปลี่ยนแปลงข้อมูลสินค้า</Button></center>
                        <div className='text-center mt-3'><span onClick={() => del()}>ลบรายการ</span></div>
                        <p></p>
                    </div>
                </div>
            </>
        )
    }

    const Resert = () => {
        if (page === 1) {
            return edit()
        } else if (page === 2) {
            return <Create />
        } else {
            return (
                <>
                    <div style={{ textAlign: 'center', color: 'silver', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: 'calc(16px + 2vmin)', minHeight: '70vh' }}>
                        <div className="bi bi-pencil-square"></div> เปลี่ยนแปลงข้อมูลสินค้า
                    </div>
                </>
            )

        }
    }

    const Feed = (props) => {
        const find = product.filter(product => product.pro_barcode === props.keys)
        find.map((product, index) => {
            setBarcode_orginal(product.pro_barcode)
            setBarcode(product.pro_barcode)
            setPro_name(product.pro_name)
            setCate(product.cate)
            setCost(product.cost)
            setPrice(product.price)
            setStock(product.stock_quantity)
            if (end_point == null || end_point == '') {
                setEnd_point(0)
            } else {
                setEnd_point(product.order_price)
            }

            setMark(product.mark)
            console.log(product.mark)
        })
        if (find.length > 0) {
            setPage(1)
        } else {
            alert('ไม่พบรายการสินค้า')
            setPage(2)
        }
    }

    return (
        <>
            <div className='container' style={{ backgroundColor: '#f5f5f5', boxShadow: '0px 1px 2px silver', borderRadius: '10px' }}>
                <Select_form />
                {Resert()}
            </div>
        </>
    )
}

const styles = {
    form: {
        boxShadow: '0px',
        boxShadow: '0px 1px 2px silver',
        borderRadius: '50px',
        // width: '400px',
    },
    select: {
        display: 'flex',
        flexDirection: 'column',
        AlignItems: 'center',
        justifyContent: 'center',
        textAlignLast: 'center',
        marginTop: '30px'
    },
}

export default Desktop