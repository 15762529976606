import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { BrowserRouter, Route, Link, Switch, useRouteMatch, useParams, useHistory, navigate } from 'react-router-dom'
import axios from 'axios'
import md5 from 'md5'
import Create_Account from './create_account'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Profile = () => {
    const { url } = useRouteMatch()
    const [data, setData] = useState([])

    const get = () => axios.post('http://apichatapi.ddns.net/api/pos/get_account_in_store_all.php', { database: localStorage.getItem('database'), store_id: localStorage.getItem('store_id') }).then(res => {
        if (res.data.length > 0) {
            setData(res.data)
        }
    })

    const del = (id, user) => {
        let con = window.confirm('คุณต้องการยกเลิกพนักงานนี้ หรือไม่')
        if (con == true) {
            axios.post('http://apichatapi.ddns.net/api/pos/del_account.php', { store_id: localStorage.getItem('store_id'), id: id, emp_user: user }).then(res => {
                if (res.data.status == 'success') {
                    alert('ยกเลิกการเป็นพนักงาน')
                    get()
                }
            })
        }
    }

    useEffect(() => {
        get()
    }, [])

    return (
        <>
            <BrowserRouter basename='/demo'>
                <Switch>
                    <Route path={`${url}`} exact>
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '100px' }}>
                                <div className='col-10 col-lg-4'>

                                    <Modal.Dialog>
                                        <Modal.Header>
                                            <Modal.Title style={{fontSize:'20px'}}>ข้อมูลพนักงาน</Modal.Title>
                                        </Modal.Header>

                                        <Modal.Body>
                                        <div className='' style={{ fontSize: '14px', marginTop: '10px', marginLeft: '10px', color: '#8E8E8E' }}>บัญชีพนักงานทั้งหมด</div>
                                    <ul class="list-group list-group-flush mt-1">
                                        {
                                            data.map(convert => {
                                                if (convert.emp_name !== localStorage.getItem('pos_name')) {
                                                    return (
                                                        <Link to={`${url}`} style={{ textDecoration: 'none' }}>
                                                            <li class="list-group-item list-group-item-action border-0">
                                                                {convert.emp_name}
                                                                <div className='float-end' onClick={() => del(convert.id, convert.emp_user)}> <span className='bi bi-trash'></span> ยกเลิก</div>
                                                            </li>
                                                        </Link>
                                                    )
                                                } else {
                                                    return (
                                                        <li class="list-group-item list-group-item-action border-0 text-center">
                                                            ไม่พบข้อมูลพนักงาน
                                                        </li>
                                                    )
                                                }
                                            })
                                        }
                                    </ul>
                                        </Modal.Body>

                                        <Modal.Footer className='border-0'>
                                            <Link variant="primary" className='nav-link pt-0 pb-0' to={`${url}/create`}>เพิ่มบัญชีพนักงาน</Link>
                                        </Modal.Footer>
                                    </Modal.Dialog>

                                </div>
                                {/* <div style={{ position: 'fixed', bottom: '50px', right: '50px', textAlign: 'center' }}>
                                    <Link to={`${url}/create`} className="btn btn-success rounded-circle mb-2" style={{ boxShadow: '0px 1px 2px silver', padding: '13px 14px 10px 14px', backgroundColor: '#2196f3', border: '0px' }}> <span className='fas fa-plus' style={{ fontSize: '25px' }} /></Link>
                                    <br /><span style={{ cursor: 'pointer' }}></span>
                                </div> */}
                            </div>
                        </>
                    </Route>
                    <Route path={`${url}/create`}>
                        <Create_Account />
                    </Route>
                </Switch>
            </BrowserRouter>
        </>
    )
}

export default Profile